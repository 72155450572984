<h2 mat-dialog-title>Confirm Restore</h2>
<div mat-dialog-content>
  <p>The following fields will be replaced:</p>
  <ul>
    <li *ngFor="let field of data.differences">{{ field }}</li>
  </ul>
  <p>Do you want to proceed?</p>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">No</button>
  <button mat-button color="primary" (click)="onYesClick()">Yes</button>
</div>
