import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService, User } from '@auth0/auth0-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../../api.service';
import { SharedDataService } from 'src/app/shared-data-service.service';
import {
  EMPTY,
  Observable,
  Subject,
  catchError,
  concatMap,
  filter,
  forkJoin,
  switchMap,
  takeUntil,
} from 'rxjs';
import {
  CancelReport,
  Customer,
  Invoice,
  Package,
} from 'src/app/models/ChargeOverModels';
import { ErrorModalComponent } from 'src/app/error-modal/error-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { EmployerContactSubscriptionDTO } from 'src/app/models/AccountManagementModels';
import { trigger, transition, useAnimation } from '@angular/animations';
import { bounceIn, fadeIn } from 'ng-animate';
import { faDownload, faCashRegister } from '@fortawesome/free-solid-svg-icons';
import { InfoModalComponent } from 'src/app/info-modal/info-modal.component';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
  FormsModule
} from '@angular/forms';
import { JobService } from '../../../services/Job.service';
import {
  ApplicationQuestionDTO,
  EmployerContactDTO,
  HousingImagesDTO,
  JobPosition,
  PositionsEmployer,
  PositionsInitialDataDTO,
  UploadedImage,
} from 'src/app/models/JobModels';
import { ConfirmRestoreDialogComponent } from 'src/app/confirm-restore-dialog/confirm-restore-dialog.component';
import { ConfirmationCodeDialogComponent } from 'src/app/confirmation-code-dialog/confirmation-code-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient, HttpEvent, HttpEventType, HttpErrorResponse } from '@angular/common/http';


@Component({
  selector: 'app-job-postings',
  templateUrl: './job-postings.component.html',
  styleUrls: ['./job-postings.component.css'],
  animations: [
    trigger('bounceIn', [transition('* => *', useAnimation(bounceIn))]),
    trigger('fadeIn', [transition('* => *', useAnimation(fadeIn))]),
  ],
})
export class JobPostingsComponent implements OnInit, OnDestroy {
  // Subscription management
  private unsubscribe$ = new Subject<void>();

  // Form controls
  jobForm: FormGroup;
  newQuestionGroup: FormGroup;

  // Loading and saving state
  isSaving: boolean = false;
  isLoading: boolean = true;
  isPositionLoading: boolean = true;
  isUpdatingControls = false;
  isUpdatingProgrammatically = false;


  // Data properties
  jobs: JobPosition[] = [];
  jobTypeList: { name: string; categoryId: number }[] = [];
  educationOptions: string[] = [];
  seasons: string[] = [];
  positionsInitialData: PositionsInitialDataDTO;
  selectedEmployer: PositionsEmployer;
  selectedJob: JobPosition;
  currentJobId: number;
  currentJobTitle: string = '';
  isPublished: boolean = false;
  jobPostingsLimitations: number = 0;
  selectAll = true;


  // UI state
  hasSavedData: boolean = false;
  today: Date = new Date();
  editIndex: number | null = null;
  previousQuestionValues: { [key: number]: any } = {};

  // User and authentication
  user: User;
  userType: string;
  portalURL: string;
  employerContactSubscription: EmployerContactSubscriptionDTO;

  // ChargeOver properties (assuming they are used elsewhere)
  customer: Customer;
  package: Package;
  invoices: Invoice[];
  cancelReport: CancelReport;
  downgradeReport: CancelReport;

  // UI icons
  faDownload = faDownload;
  faCashRegister = faCashRegister;


  selectedFiles: File[] = [];
  maxFiles = 5;

  allowedFileTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'];

  // other
  quillModules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'], 
      [{ 'header': 1 }, { 'header': 2 }, { 'header': 3 } ],   // custom button values
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'indent': '-1' }, { 'indent': '+1' }],
      [{ 'size': ['small', false, 'large', 'huge'] }],
      [{ 'header': [1, 2, 3, false] }],
      [{ 'color': [] }, { 'background': [] }],
      [{ 'align': [] }],
      ['clean'] // remove formatting button
    ],
    customOptions: [{
      import: 'formats/font',
      whitelist: ['Montserrat']
    }]
  };


  // Animation triggers
  public bounceIn: any;
  public fadeIn: any;

  // Error modal state
  chargeoverProfileErrorOpened: boolean = false;

  constructor(
    private fb: FormBuilder,
    public auth: AuthService,
    private sharedDataService: SharedDataService,
    private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private jobService: JobService,
    private snackBar: MatSnackBar,
    private http: HttpClient
  ) { }

  ngOnInit() {
    this.setupAuthSubscription();
    this.initializeForm();
    this.fetchInitialData();
    this.checkForSavedData();
    this.autoSaveForm();
  }

  private fetchInitialData() {
    this.fetchJobTypes();
    this.fetchEducationOptions();
  }
  
  private setupAuthSubscription() {
    this.auth.user$
      .pipe(
        filter((user) => !!user && !!user.nickname),
        switchMap((user) => this.handleAuthUser(user)),
        catchError((e) => this.handleError(e)),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((employerContactSubscription) => {

        this.handleEmployerContactSubscription(employerContactSubscription);
      });
  }

  private initializeForm() {
    this.jobForm = this.fb.group({
      employerPositionId: [null],
      published: [false],
      postDate: [null, Validators.required],
      removalDate: [
        null,
        [Validators.required, this.removalDateValidator.bind(this)],
      ],
      season: [null, Validators.required],
      positionCategoryId: [null],
      startDate: [null, Validators.required],
      endDate: [
        null,
        [Validators.required, this.endDateValidator.bind(this)],
      ],
      jobTypes: this.fb.array([], this.minSelectedCheckboxes(1)),
      contacts: this.fb.array([], this.minSelectedCheckboxes(1)),
      jobTitle: [null, Validators.required],
      jobDescription: [null, Validators.required],
      skills: [null],
      openings: [null, [Validators.min(1), Validators.required]],
      manualLabor: [null, Validators.required],
      supervisesOthers: [null, Validators.required],
      trainingRequired: [null, Validators.required],
      onTheJobTraining: [null, Validators.required],
      education: [null],
      experience: [null, Validators.required],
      certifications: [null],
      drugScreening: [null, Validators.required],
      backgroundCheck: [null, Validators.required],
      ownCar: [null, Validators.required],
      driversLicense: [null, Validators.required],
      covidTest: [null, Validators.required],
      covidVaccine: [null, Validators.required],
      american: [false],
      j1: [false],
      inCountryH2B: [false],
      outOfCountryH2B: [false],
      hoursPerWeek: [null],
      wageMin: [null],
      wageMax: [null],
      overtimePay: [null, Validators.required],
      pieceWorkPay: [null, Validators.required],
      gratuities: [null, Validators.required],
      bonus: [null, Validators.required],
      otherCompensation: [null],
      healthBenefits: [false],
      dentalBenefits: [false],
      retentionBonus: [false],
      referralBonus: [false],
      offer401k: [false],
      paidTimeOff: [false],
      otherBenefits: [null],
      employerProvidesHousing: [null],
      employerOwned: [null],
      employerHousingAmount: [null],
      workerHousingAmount: [null],
      deposit: [null],
      utilitiesIncluded: [null],
      onProperty: [false],
      offProperty: [false],
      shared: [false],
      individual: [false],
      house: [false],
      apartment: [false],
      dormitory: [false],
      otherHousing: [null],
      employerProvidesTransportation: [null],
      publicTransportation: [false],
      shuttle: [false],
      car: [false],
      bicycle: [false],
      carpool: [false],
      otherTransport: [null],
      transportCost: [null],
      deleted: [false],
      applicationQuestions: this.fb.array([]),
      housingImages: this.fb.array([]),
      publicPosition: false,
      isOtherTransport: false,
      isOtherHousing: false,
      isOtherBenefits: false,
      isOtherCompensation: false,
    }, { validators: this.atLeastOneWorkerTypeSelectedValidator });

    this.newQuestionGroup = this.fb.group({
      text: [''],
      required: [false],
    });

    

    // Update validation when 'removalDate' changes
    this.jobForm
      .get('removalDate')
      .valueChanges.subscribe(() => this.jobForm.updateValueAndValidity());

    ['american', 'inCountryH2B', 'outOfCountryH2B', 'j1'].forEach(controlName => {
      this.jobForm.get(controlName)?.valueChanges.subscribe(() => {
        this.jobForm.updateValueAndValidity();
      });
    });

    // Subscribe to positionCategoryId changes
    this.jobForm.get('positionCategoryId').valueChanges.subscribe((value) => {
      this.jobTypes.controls.forEach((control, index) => {
        const jobType = this.jobTypeList[index];
        if (jobType.categoryId !== value) {
          if (control.value) {
            control.setValue(false, { emitEvent: false });
          }

          control.disable({ emitEvent: false });

        } else {
          control.enable({ emitEvent: false });
        }
      });
    });

    // Subscribe to jobTypes value changes
    this.jobTypes.valueChanges.subscribe((values: boolean[]) => {
      // Get the list of selected job type names based on `values`
      const selectedJobTypeNames = this.getSelectedJobTypes()

      if (selectedJobTypeNames.length > 0) {
        // Find the categoryId of the first selected job type by name
        const firstSelectedJobTypeName = selectedJobTypeNames[0];
        const firstSelectedJobType = this.jobTypeList.find(
          (jobType) => jobType.name === firstSelectedJobTypeName
        );

        if (firstSelectedJobType) {
          const selectedCategoryId = firstSelectedJobType.categoryId;

          // Disable/enable job types based on the selected category
          this.jobTypes.controls.forEach((control, index) => {
            const jobType = this.jobTypeList[index];
            if (jobType.categoryId !== selectedCategoryId) {
              control.disable({ emitEvent: false });
            } else {
              control.enable({ emitEvent: false });
            }
          });

          // Update positionCategoryId only if it matches
          this.jobForm.patchValue(
            { positionCategoryId: selectedCategoryId },
            { emitEvent: false }
          );
        }
      } else {
        // Enable all job types if none are selected
        this.jobTypes.controls.forEach((control) => control.enable({ emitEvent: false }));

        // Clear positionCategoryId
        this.jobForm.patchValue({ positionCategoryId: null }, { emitEvent: false });
      }
    });

  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  // Initialization methods
 

  private setEmployerContacts() {
    const employerContacts = this.positionsInitialData.employerContacts || [];
    const contactsArray = this.jobForm.get('contacts') as FormArray;

    employerContacts.forEach(() => contactsArray.push(this.fb.control(false)));
  }





  private handleEmployerContactSubscription(
    employerContactSubscription: EmployerContactSubscriptionDTO
  ) {
    if(employerContactSubscription.plan.subscriptionStatusId != 1)
      {
        this.router.navigate(['/'])
      }
    this.employerContactSubscription = employerContactSubscription;
    const employerId = employerContactSubscription.employer.employerId;
    if (!sessionStorage.getItem('ActiveEmployerId')) {
      sessionStorage.setItem('ActiveEmployerId', employerId.toString());
    }
    this.fetchPositionsInitialData(employerId);
    this.fetchChargeoverProfile(employerId);
  }

  // Custom Validator to ensure at least one worker type is selected
  private atLeastOneWorkerTypeSelectedValidator(group: FormGroup): ValidationErrors | null {
    const american = group.get('american')?.value;
    const inCountryH2B = group.get('inCountryH2B')?.value;
    const outOfCountryH2B = group.get('outOfCountryH2B')?.value;
    const j1 = group.get('j1')?.value;

    return american || inCountryH2B || outOfCountryH2B || j1
      ? null
      : { atLeastOneWorkerType: true };
  }

  private handleAuthUser(user: User) {
    const arrMeta = user.nickname.split(':');
    const employerContactId = Number.parseInt(arrMeta[1], 10);
    this.userType = arrMeta[0];
    this.portalURL = this.sharedDataService.getData('portalURL');
    if (this.userType === 'E') {
      return this.apiService.getPrimaryEmployerForContact(employerContactId);
    } else if (this.userType === 'S') {
      this.redirectSeasoanalWorker();
      return EMPTY;
    } else if(this.userType === 'A') {
      return this.apiService.getEmployerForAdmin( Number.parseInt(sessionStorage.getItem('ActiveEmployerId') || '0',10));
    }
    return EMPTY;
  }

  private redirectSeasoanalWorker() {
    if (!this.portalURL) {
      this.apiService.GetConfigurationParam('PortalURL').subscribe((res) => {
        this.portalURL = res;
        this.sharedDataService.setData<string>('portalURL', res);
        window.location.href = this.portalURL;
      });
    } else {
      window.location.href = this.portalURL;
    }
  }


  private fetchPositionsInitialData(employerId: number) {
    this.apiService
      .getPositionsInitialDataForEmployer(employerId)
      .pipe(
        concatMap((initialData) => {
          this.fetchJobs();
          this.positionsInitialData = initialData;
          this.seasons = initialData.seasons;
          this.jobPostingsLimitations = initialData.jobPostingsLimitations;
          this.checkForEdit();
          this.setEmployerContacts();
          if (this.positionsInitialData.associatedEmployers.length > 1) {
            this.selectedEmployer =
              this.positionsInitialData.associatedEmployers.find(
                (ae) =>
                  ae.employerId ===
                  Number.parseInt(
                    sessionStorage.getItem('ActiveEmployerId') || '0',
                    10
                  )
              );
          }
          return EMPTY;
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  private fetchChargeoverProfile(employerId: number) {
    this.apiService
      .getChargeoverProfile(employerId)
      .pipe(
        concatMap((customer) => {
          this.customer = customer;
          return this.apiService.getChargeoverSubscription(employerId).pipe(
            catchError((subscriptionError) => {
              console.error('Subscription error:', subscriptionError);
              if (subscriptionError.error.errorCode === 2) {
                this.router.navigate(['/buy-subscription']);
              } else {
                this.openErrorModal(subscriptionError);
              }
              return EMPTY;
            })
          );
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  private handleError(error: any) {
    console.error(error);
    this.openErrorModal(error);
    this.router.navigate(['/error']);
    return EMPTY;
  }

  // Data fetching methods
  private fetchJobs() {
    this.isPositionLoading = true;
    this.jobService.fetchJobs().subscribe(
      (jobs) => {
        this.jobs = jobs;
        this.isPositionLoading = false;
      },
      (error) => {
        this.isPositionLoading = false;
        this.openErrorModal(error);
      }
    );
  }

  private refreshJobs() {
    this.jobService.refreshJobs().subscribe(
      () => {
        this.isPositionLoading = false;
        this.fetchJobs();
      },
      (error) => {
        this.isPositionLoading = false;
      }
    );
  }

  private fetchJobTypes() {

    this.jobTypeList = [
      { name: 'Server', categoryId: 6 },
      { name: 'Bartender', categoryId: 6 },
      { name: 'Busser', categoryId: 6 },
      { name: 'Barista', categoryId: 6 },
      { name: 'Food Runner', categoryId: 6 },
      { name: 'Host/Hostess', categoryId: 6 },
      { name: 'Server Assistant', categoryId: 6 },
      { name: 'Dining Room Supervisor', categoryId: 6 },
      { name: 'Cook', categoryId: 3 },
      { name: 'Dishwasher', categoryId: 3 },
      { name: 'Housekeeper', categoryId: 8 },
      { name: 'Housekeeping Supervisor', categoryId: 8 },
      { name: 'Groundskeeper/ Landscaper', categoryId: 9 },
      { name: 'Golf Course Maintenance', categoryId: 9 },
      { name: 'Lift Operator', categoryId: 10 },
      { name: 'Ski Instructor', categoryId: 10 },
      { name: 'Snow Maker', categoryId: 10 },
      { name: 'Front Desk Agent', categoryId: 7 },
      { name: 'Beach/Pool Attendant', categoryId: 7 },
      { name: 'Miscellaneous', categoryId: 7 },
    ];

    this.jobTypeList.forEach(() =>
      this.jobTypes.push(this.fb.control(false))
    );

  }

  private fetchEducationOptions() {
    setTimeout(() => {
      this.educationOptions = [
        'None',
        'High School/GED',
        'Bachelor’s Degree or equivalent',
        'Graduate degree or equivalent',
        // Add other education options as needed
      ];
    }, 100);
  }

  // Form utility methods
  get jobTypes(): FormArray {
    return this.jobForm.get('jobTypes') as FormArray;
  }
  get contacts(): FormArray {
    return this.jobForm.get('contacts') as FormArray;
  }

  private minSelectedCheckboxes(min = 1) {
    return (formArray: FormArray): ValidationErrors | null => {
      const totalSelected = formArray.controls
        .map((control) => control.value)
        .reduce((prev, next) => (next ? prev + 1 : prev), 0);
      return totalSelected >= min ? null : { required: true };
    };
  }

  private removalDateValidator(
    control: AbstractControl
  ): ValidationErrors | null {
    const removalDateValue = control.value;
    const parent = control.parent;
    if (!parent) {
      return null;
    }
    const postDateValue = parent.get('postDate')?.value;
    if (!postDateValue || !removalDateValue) {
      return null;
    }
    const postDate = new Date(postDateValue);
    const removalDate = new Date(removalDateValue);
    return removalDate.getTime() > postDate.getTime()
      ? null
      : { dateRange: true };
  }

  private endDateValidator(control: AbstractControl): ValidationErrors | null {
    const endDateValue = control.value;
    const parent = control.parent;
    if (!parent) {
      return null;
    }
    const startDateValue = parent.get('startDate')?.value;
    if (!startDateValue || !endDateValue) {
      return null;
    }
    const startDate = new Date(startDateValue);
    const endDate = new Date(endDateValue);
    return endDate.getTime() > startDate.getTime()
      ? null
      : { dateRange: true };
  }

  private updateJobTypesCheckboxes(selectedJobTypes: string[]) {
    if (this.jobTypes.length === 0) {
      this.jobTypeList.forEach(() =>
        this.jobTypes.push(this.fb.control(false))
      );
    }
    this.jobTypes.controls.forEach((control, index) => {
      const jobType = this.jobTypeList[index];
      const isSelected = selectedJobTypes.includes(jobType.name);
      control.setValue(isSelected);
    });
  }

  private updateContactsCheckboxes(selectedContacts: EmployerContactDTO[]) {
    const contactsArray = this.jobForm.get('contacts') as FormArray;

    // Ensure the FormArray matches the available contacts
    if (contactsArray.length === 0) {
      this.positionsInitialData.employerContacts.forEach(() =>
        contactsArray.push(this.fb.control(false))
      );
    }

    // Update the FormArray controls based on selected contacts
    contactsArray.controls.forEach((control, index) => {
      const contact = this.positionsInitialData.employerContacts[index];
      const isSelected = selectedContacts.some(
        (selected) => selected.employerContactId === contact.employerContactId
      );
      control.setValue(isSelected);
    });
  }


  // Form save and restore methods
  private checkForSavedData() {
    const savedForm = localStorage.getItem('jobForm');
    if (savedForm) {
      const formDataWithTimestamp = JSON.parse(savedForm);
      const formData = formDataWithTimestamp.data;
      const hasNonEmptyFields = this.hasNonEmptyFields(formData);
      const now = new Date().getTime();
      const expiryTime = 72 * 60 * 60 * 1000; // 72 hours
      if (
        hasNonEmptyFields &&
        now - formDataWithTimestamp.timestamp < expiryTime
      ) {
        this.hasSavedData = true;
      } else {
        localStorage.removeItem('jobForm');
        this.hasSavedData = false;
      }
    } else {
      this.hasSavedData = false;
    }
  }

  private autoSaveForm() {
    this.jobForm.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((val) => {
        const hasNonEmptyFields = this.hasNonEmptyFields(val);
        if (hasNonEmptyFields && this.jobForm.dirty) {
          const formDataWithTimestamp = {
            data: val,
            timestamp: new Date().getTime(),
          };
          localStorage.setItem('jobForm', JSON.stringify(formDataWithTimestamp));
        }
      });
  }

  private hasNonEmptyFields(formData: any): boolean {
    for (let key in formData) {
      if (formData.hasOwnProperty(key)) {
        const value = formData[key];
        if (this.isNonEmpty(value)) {
          return true;
        }
      }
    }
    return false;
  }

  private isNonEmpty(value: any): boolean {
    if (Array.isArray(value)) {
      return value.some((item) => this.isNonEmpty(item));
    } else if (typeof value === 'object' && value !== null) {
      return Object.values(value).some((propValue) => this.isNonEmpty(propValue));
    } else if (typeof value === 'boolean') {
      return value === true;
    } else {
      return value !== null && value !== undefined && value !== '';
    }
  }

  private getDifferences(currentData: any, savedData: any): string[] {
    const differences: string[] = [];
    for (const key in savedData) {
      if (savedData.hasOwnProperty(key)) {
        const currentValue = currentData[key];
        const savedValue = savedData[key];
        if (!this.valuesAreEqual(currentValue, savedValue)) {
          differences.push(
            this.getFieldLabel(key) + ': ' + this.getSavedValue(savedValue)
          );
        }
      }
    }
    return differences;
  }

  private valuesAreEqual(val1: any, val2: any): boolean {
    if (Array.isArray(val1) && Array.isArray(val2)) {
      return JSON.stringify(val1) === JSON.stringify(val2);
    }
    if (typeof val1 === 'object' && typeof val2 === 'object') {
      return JSON.stringify(val1) === JSON.stringify(val2);
    }
    return val1 === val2;
  }

  private getFieldLabel(fieldName: string, customLabel?: string): string {
    const fieldLabels: { [key: string]: string } = {
      employerPositionId: 'Employer Position #',
      positionCategoryId: 'Position Category',
    };

    if (customLabel) {
      return customLabel;
    }

    const formattedLabel =
      fieldLabels[fieldName] ||
      fieldName
        .replace(/([A-Z])/g, ' $1')
        .replace(/^./, (str) => str.toUpperCase());

    return formattedLabel.trim();
  }

  private getSavedValue(value: any): any {
    if (value === null) {
      return 'Empty';
    }
    if (value === true) {
      return 'Yes';
    }
    if (value === false) {
      return 'No';
    }
    if (
      typeof value === 'string' &&
      /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/.test(value)
    ) {
      const date = new Date(value);
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const year = date.getFullYear();
      return `${month}/${day}/${year}`;
    }
    return value;
  }

  // Action methods
  private checkForEdit() {
    this.route.queryParams.subscribe((params) => {
      if (params['EmployerPositionId']) {
        this.currentJobId = +params['EmployerPositionId'];
        this.loadJob(this.currentJobId);
      } else {
        this.currentJobId = null;
        this.currentJobTitle = '';
        this.isPublished = false;
      }
    });
  }

  private loadJob(id: number) {
    this.jobService.getJobById(id).subscribe((job) => {
      if (job) {
        this.jobForm.patchValue(job);
        if (job.jobTypes && Array.isArray(job.jobTypes)) {
          this.updateJobTypesCheckboxes(job.jobTypes || []);
        }
        if (job.employerContacts && Array.isArray(job.employerContacts)) {
          this.updateContactsCheckboxes(job.employerContacts);
        }
        if (job.applicationQuestions && Array.isArray(job.applicationQuestions)) {
          this.populateApplicationQuestions(job.applicationQuestions);
        }
        if(job.housingImages && Array.isArray(job.housingImages)) {
          this.populateHousingImages(job.housingImages);
        }
        this.currentJobTitle = job.jobTitle;
        this.isPublished = job.published || false;
      } else {
        this.currentJobTitle = '';
        this.isPublished = false;
        this.snackBar.open('Job not found, please select correct employer first', 'Close', {
          duration: 5000, // Duration in milliseconds
          horizontalPosition: 'center', // 'start' | 'center' | 'end' | 'left' | 'right'
          verticalPosition: 'bottom', // 'top' | 'bottom'
        });
        this.removeQueryParams();
      }
    });
  }

  removeQueryParams() {
    const currentUrl = this.router.url.split('?')[0];
    this.router.navigate([currentUrl], { queryParams: {} }); // Navigate to the same URL but with empty query parameters
  }

  reloadPageWithoutParams() {
    const currentPath = this.router.url.split('?')[0];
    window.history.replaceState({}, '', currentPath);
    window.location.reload();
  }

  deleteJob() {
    const confirmDialog = this.dialog.open(ConfirmationCodeDialogComponent, {
      width: '400px',
      data: {
        message: 'Are you sure you want to delete this job?',
      },
    });
  
    confirmDialog.afterClosed().subscribe((result) => {
      if (result) {
        this.apiService
          .deletePosition(Number.parseInt(this.jobForm.get('employerPositionId').value))
          .pipe(takeUntil(this.unsubscribe$)) 
          .subscribe(
            (response) => {
              this.snackBar.open('Job deleted successfully', 'Close', {
                duration: 5000,
                horizontalPosition: 'center',
                verticalPosition: 'bottom',
              });
              this.refreshJobs();
              this.removeQueryParams();
              this.jobForm.reset();
            },
            (error) => {
              this.openErrorModal(error);
            }
          );
      }
    });
  }

  editJob(job: JobPosition) {
    if (this.jobForm.dirty && !this.jobForm.pristine) {
      const confirmDialog = this.dialog.open(ConfirmationCodeDialogComponent, {
        width: '400px',
        data: {
          message: 'You have unsaved changes. Do you want to discard them?',
        },
      });

      confirmDialog.afterClosed().subscribe((result) => {
        if (result) {
          localStorage.removeItem('jobForm');
          this.router.navigate([], {
            queryParams: { EmployerPositionId: job.employerPositionId },
          });
        }
      });
    } else {
      this.router.navigate([], {
        queryParams: { EmployerPositionId: job.employerPositionId },
      });
    }
  }

  importPosition() {
    if (this.selectedJob) {
      this.jobForm.patchValue(this.selectedJob);
      this.isPublished = false;
      this.jobForm.get('employerPositionId').setValue(null);
    } else {
      this.openInfoModal('Please select a position to import.');
    }
  }

  setActiveEmployer() {
    if (this.selectedEmployer) {
      sessionStorage.setItem(
        'ActiveEmployerId',
        this.selectedEmployer.employerId.toString()
      );
      this.reloadPageWithoutParams();
    } else {
      this.openInfoModal('Please select an employer.');
    }
  }

  restoreSavedData() {
    const savedForm = localStorage.getItem('jobForm');
    if (savedForm) {
      const formDataWithTimestamp = JSON.parse(savedForm);
      const savedData = formDataWithTimestamp.data;
      const currentData = this.jobForm.value;
      const differences = this.getDifferences(currentData, savedData);
  
      const restore = () => {
        if (Array.isArray(savedData.housingImages)) {
          this.housingImages.clear();
  
          savedData.housingImages.forEach((imgData: HousingImagesDTO) => {
            this.housingImages.push(this.createHousingImageFormGroup(imgData));
          });
        }
  
        // Now remove housingImages from savedData so you don't overwrite it again with patchValue.
        const { housingImages, ...otherData } = savedData;
        // Patch the other parts of the form
        this.jobForm.patchValue(otherData);
  
        this.openInfoModal('Draft restored from local storage.');
        this.updateCurrentJobInfo();
      };
  
      if (differences.length === 0) {
        restore();
      } else {
        const dialogRef = this.dialog.open(ConfirmRestoreDialogComponent, {
          width: '400px',
          data: { differences },
        });
  
        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            restore();
          }
        });
      }
    } else {
      this.openInfoModal('No saved draft found.');
      this.hasSavedData = false;
    }
  }
  

  clearSavedData() {
    this.jobForm.reset();
    localStorage.removeItem('jobForm');
    this.hasSavedData = false;
    this.openInfoModal('Draft cleared.');
  }

  private updateCurrentJobInfo() {
    this.currentJobTitle = this.jobForm.get('jobTitle').value || '';
    this.isPublished = this.jobForm.get('published').value || false;
  }

  saveAsDraft() {
    this.jobForm.markAllAsTouched();

    const val = this.jobForm.value;
    const hasNonEmptyFields = this.hasNonEmptyFields(val);
    if (hasNonEmptyFields) {
      const formDataWithTimestamp = {
        data: val,
        timestamp: new Date().getTime(),
      };
      this.jobForm.get('published').setValue(false);
      localStorage.setItem('jobForm', JSON.stringify(formDataWithTimestamp));
      this.hasSavedData = true;
      this.save(false);
    } else {
      this.openInfoModal('Cannot save an empty form.');
    }
  }

  publish() {
    this.jobForm.get("published").setValue(true);
    if (this.jobPostingsLimitations === 0) {
      this.save(true);
    } else {
      const publishedJobsCount = this.jobs.filter(job => job.published).length;
      if (publishedJobsCount >= this.jobPostingsLimitations) {
        this.openInfoModalWithUrlAndTitle('You have reached the maximum number of published jobs for your plan. Please upgrade your subscription to be able to publish additonal jobs.', '/change-subscription', 'Limit reached');
      } else {
        this.save(true);
      }
    }
  }

  private save(wipeStorage: boolean) { 
    if (this.jobForm.valid) {
      const jobPosition = this.mapFormToJobPosition();
      this.isSaving = true;

      this.apiService
        .savePositionForEmployer(
          Number.parseInt(sessionStorage.getItem('ActiveEmployerId'), 10),
          jobPosition
        )
        .subscribe(
          (response) => {
            // Redirect instead of reloading the page
            this.refreshJobs();
            if (wipeStorage) {
              this.jobForm.get('published').setValue(true);
              localStorage.removeItem('jobForm');
              this.hasSavedData = false;
            }
            this.housingImages.removeAt
            this.isSaving = false;
            this.fetchJobs();
            this.jobForm.markAsPristine();
            this.jobForm.markAsUntouched();
            const housingImagesArray = this.housingImages; // Assuming this.housingImages is your FormArray

            for (let i = housingImagesArray.length - 1; i >= 0; i--) {
              if (housingImagesArray.at(i).get('deleted')?.value === true) {
                housingImagesArray.removeAt(i);
              }
            }

            this.snackBar.open('Saved successfully!', 'Close', {
              duration: 2500, // Duration in milliseconds
              horizontalPosition: 'center', // 'start' | 'center' | 'end' | 'left' | 'right'
              verticalPosition: 'bottom', // 'top' | 'bottom'
            });
            this.router.navigate([], {
              queryParams: { EmployerPositionId: response.employerPositionId },
            });

          },
          (error) => {
            this.fetchJobs();
            this.isSaving = false;
            this.openErrorModal(error);
          }
        );
    } else {
      this.jobForm.markAllAsTouched();
      const invalidFields = Object.keys(this.jobForm.controls).filter((key) => {
        const control = this.jobForm.get(key);
        return control && control.invalid;
      });
      
      // Convert the invalid field keys into user-friendly labels
      const invalidFieldLabels = invalidFields.map(field => this.getFieldLabel(field));
      
      // Construct the message including all invalid fields
      let message = 'You have invalid fields: ';
      if (invalidFieldLabels.length > 0) {
        message += invalidFieldLabels.join(', ');
      } else {
        message = 'You have invalid fields, please check all inputs';
      }
      
      // Show the snackbar with the message listing the invalid fields
      this.snackBar.open(message, 'Close', {
        duration: 10000, // Duration in milliseconds
        horizontalPosition: 'center', // Possible values: 'start' | 'center' | 'end' | 'left' | 'right'
        verticalPosition: 'bottom',   // Possible values: 'top' | 'bottom'
      });
    }  
  }

  onSubmit() {
    // Handle form submission if needed
  }

  // Application questions methods
  get applicationQuestions(): FormArray {
    return this.jobForm.get('applicationQuestions') as FormArray;
  }

  private populateHousingImages(housingImagesDTO: any[]) {
    this.housingImages.clear();

    housingImagesDTO.forEach(dto => {
      this.housingImages.push(this.createHousingImageFormGroup(dto));
    });  
  }

  private createHousingImageFormGroup(dto?: any): FormGroup {
    return this.fb.group({
      imageId: [dto?.imageId || null],
      imageUrl: [dto?.imageUrl || ''],
      deleted: [dto?.deleted || false],
      photoCaption: [dto?.photoCaption || ''],
      sortOrder: [dto?.sortOrder || 0],
      file: [null],         // not submitted directly, but for internal handling
      preview: [dto?.imageUrl || ''],
      isUploading: [false],
      isUploaded: [!!dto?.imageUrl],
      isError: [false],
      uploadProgress: [dto?.imageUrl ? 100 : 0]
    });
  }

  private populateApplicationQuestions(questionsData: any[]) {
    this.applicationQuestions.clear();
    questionsData.forEach((question) => {
      this.applicationQuestions.push(
        this.fb.group({
          text: [question.question || question],
          required: [question.isRequired || false],
        })
      );
    });
  }

  addQuestion() {

    if (this.newQuestionGroup.valid) {
      this.applicationQuestions.push(
        this.fb.group({
          text: [this.newQuestionGroup.get('text')?.value.trim(), Validators.required],
          required: [this.newQuestionGroup.get('required')?.value],
        })
      );
      this.newQuestionGroup.reset({ text: '', required: false });
    }
  }

  editQuestion(index: number) {
    this.editIndex = index;
    const questionGroup = this.applicationQuestions.at(index) as FormGroup;
    this.previousQuestionValues[index] = { ...questionGroup.value };
  }

  saveEditedQuestion(index: number) {
    const questionGroup = this.applicationQuestions.at(index) as FormGroup;
    if (questionGroup.valid) {
      delete this.previousQuestionValues[index];
      this.editIndex = null;
    }
  }

  cancelEdit(index: number) {
    const questionGroup = this.applicationQuestions.at(index) as FormGroup;
    if (this.previousQuestionValues[index]) {
      questionGroup.setValue(this.previousQuestionValues[index]);
    }
    this.editIndex = null;
  }

  removeQuestion(index: number) {
    this.applicationQuestions.removeAt(index);
    if (this.editIndex === index) {
      this.cancelEdit(index);
    } else if (this.editIndex !== null && this.editIndex > index) {
      this.editIndex--;
    }
  }

  // Mapping methods
  private mapFormToJobPosition(): JobPosition {
    const formValue = this.jobForm.value;
    const jobPosition: JobPosition = {
      deleted: formValue.deleted || false,
      positionPublic: formValue.publicPosition || false,
      employerPositionId: formValue.employerPositionId,
      published: formValue.published,
      postDate: this.convertToUTC(formValue.postDate),
      removalDate: this.convertToUTC(formValue.removalDate),
      season: formValue.season,
      positionCategoryId: formValue.positionCategoryId,
      startDate: this.convertToUTC(formValue.startDate),
      endDate: this.convertToUTC(formValue.endDate),
      jobTypes: this.getSelectedJobTypes(),
      employerContacts: this.getSelectedEmployerContacts(),
      jobTitle: formValue.jobTitle,
      jobDescription: formValue.jobDescription,
      skills: formValue.skills,
      openings: formValue.openings,
      manualLabor: formValue.manualLabor,
      supervisesOthers: formValue.supervisesOthers,
      trainingRequired: formValue.trainingRequired,
      onTheJobTraining: formValue.onTheJobTraining,
      education: formValue.education,
      experience: formValue.experience,
      certifications: formValue.certifications,
      drugScreening: formValue.drugScreening,
      backgroundCheck: formValue.backgroundCheck,
      ownCar: formValue.ownCar,
      driversLicense: formValue.driversLicense,
      covidTest: formValue.covidTest,
      covidVaccine: formValue.covidVaccine,
      american: formValue.american,
      j1: formValue.j1,
      inCountryH2B: formValue.inCountryH2B,
      outOfCountryH2B: formValue.outOfCountryH2B,
      hoursPerWeek: formValue.hoursPerWeek,
      wageMin: formValue.wageMin,
      wageMax: formValue.wageMax,
      overtimePay: formValue.overtimePay,
      pieceWorkPay: formValue.pieceWorkPay,
      gratuities: formValue.gratuities,
      bonus: formValue.bonus,
      otherCompensation: formValue.otherCompensation,
      healthBenefits: formValue.healthBenefits,
      dentalBenefits: formValue.dentalBenefits,
      retentionBonus: formValue.retentionBonus,
      referralBonus: formValue.referralBonus,
      offer401k: formValue.offer401k,
      paidTimeOff: formValue.paidTimeOff,
      otherBenefits: formValue.otherBenefits,
      employerProvidesHousing: formValue.employerProvidesHousing,
      employerOwned: formValue.employerOwned,
      employerHousingAmount: formValue.employerHousingAmount,
      workerHousingAmount: formValue.workerHousingAmount,
      deposit: formValue.deposit,
      utilitiesIncluded: formValue.utilitiesIncluded,
      onProperty: formValue.onProperty,
      offProperty: formValue.offProperty,
      shared: formValue.shared,
      individual: formValue.individual,
      house: formValue.house,
      apartment: formValue.apartment,
      dormitory: formValue.dormitory,
      otherHousing: formValue.otherHousing,
      employerProvidesTransportation: formValue.employerProvidesTransportation,
      publicTransportation: formValue.publicTransportation,
      shuttle: formValue.shuttle,
      car: formValue.car,
      bicycle: formValue.bicycle,
      carpool: formValue.carpool,
      otherTransport: formValue.otherTransport,
      transportCost: formValue.transportCost,
      applicationQuestions: this.mapApplicationQuestions(),
      housingImages: formValue.housingImages.map((img: any) => ({
        imageId: img.imageId,
        imageUrl: img.imageUrl,
        deleted: img.deleted,
        photoCaption: img.photoCaption,
        sortOrder: img.sortOrder
      })),
      pendingPublish: (formValue.postDate > new Date() && formValue.published)
    };
    return jobPosition;
  }

  getJobTypesByCategoryId(categoryId: number): { name: string; categoryId: number }[] {
    return this.jobTypeList.filter(jobType => jobType.categoryId === categoryId);
  }



  private getSelectedJobTypes(): string[] {
    return this.jobTypes.controls
      .map((control, i) => (control.value ? this.jobTypeList[i].name : null))
      .filter(value => value !== null);
  }

  private getSelectedEmployerContacts(): EmployerContactDTO[] {
    const employerContactsArray = this.jobForm.get('contacts') as FormArray;
    const selectedIndices = employerContactsArray.controls
      .map((control, index) => (control.value ? index : null))
      .filter(index => index !== null);

    return selectedIndices.map(index => this.positionsInitialData.employerContacts[index]);
  }
  
  getVisibilityStatus(job: any): string {
    const currentDate = new Date();
    const postDate = new Date(job.postDate); // Assuming `postDate` is in a valid date format
    const removalDate = new Date(job.removalDate); // Assuming `removalDate` is in a valid date format
  
    if (job.published) {
      return 'Published';
    } else if (currentDate <= postDate && job.pendingPublish) {
      return 'Pending Post Date';
    } else if (!job.published && currentDate > removalDate) {
      return 'Posting Ended';
    } else {
      return 'Draft';
    }
  }
  


  private mapApplicationQuestions(): ApplicationQuestionDTO[] {
    const applicationQuestionsArray = this.jobForm.get(
      'applicationQuestions'
    ) as FormArray;
    return applicationQuestionsArray.controls.map((group) => {
      const q = group.value;
      return {
        question: q.text,
        isRequired: q.required,
      };
    });
  }

  
  private mapUploadedImagesToDTO(uploadedImages: UploadedImage[]): HousingImagesDTO[] {
    return uploadedImages.map((uploadedImage) => ({
      imageId: uploadedImage.imageId,
      imageUrl: uploadedImage.uploadedUrl || uploadedImage.preview, 
      deleted: uploadedImage.deleted,
      sortOrder: 0,
      photoCaption: ""
    }));
  }

  private mapDTOToUploadedImages(housingImagesDTO: HousingImagesDTO[]): UploadedImage[] {
    return housingImagesDTO.map((dto) => ({
      imageId: dto.imageId,
      file: null, 
      preview: dto.imageUrl,
      uploadProgress: dto.imageUrl ? 100 : 0, 
      uploadedUrl: dto.imageUrl,
      isUploading: false,
      isUploaded: !!dto.imageUrl, 
      isError: false,
      deleted: dto.deleted,
      photoCaption:"",
      sortOrder: 0,
    }));
  }


  toggleSelectAll(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
  
    const contactsArray = this.jobForm.get('contacts') as FormArray;
    if (contactsArray) {
      this.positionsInitialData.employerContacts.forEach((_, index) => {
        if (contactsArray.at(index)) {
          contactsArray.at(index).setValue(this.selectAll);
        }
      });
    }
    this.selectAll = !this.selectAll;
  }


  // Modal methods
  private openInfoModal(message: string): void {
    this.dialog.open(InfoModalComponent, {
      data: { message: message },
      maxWidth: '70vw',
      maxHeight: '80vh',
    });
  }
  private openInfoModalWithUrlAndTitle(message: string, url: string, title: string): void {
    this.dialog.open(InfoModalComponent, {
      data: { message: message, url: url, title: title },
      maxWidth: '70vw',
      maxHeight: '80vh',
    });
  }

  private openErrorModal(error: any): void {
    if (!this.chargeoverProfileErrorOpened) {
      this.chargeoverProfileErrorOpened = true;
      const dialogRef = this.dialog.open(ErrorModalComponent, {
        data: error,
        maxWidth: '70vw',
        maxHeight: '80vh',
      });

      dialogRef.afterClosed().subscribe(() => {
        this.chargeoverProfileErrorOpened = false;
      });
    }
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (!input.files) return;
  
    const filesArray = Array.from(input.files);
    for (let file of filesArray) {
      if (file.size > 1548576) {
        this.openInfoModal('File size must be 1MB or smaller. Please select a smaller file.');
        input.value = '';
        continue;
      }
  
      if (!this.allowedFileTypes.includes(file.type)) {
        this.openInfoModal(`File "${file.name}" not supported. Allowed formats: .jpg, .jpeg, .png, .gif`);
        continue;
      }
  
      // Check maximum allowed images
      if (this.undeletedCount >= this.maxFiles) {
        this.openInfoModal(`You have reached the maximum of ${this.maxFiles} images.`);
        break;
      }
  
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const newImageGroup = this.createHousingImageFormGroup({
          deleted: false,
          sortOrder: 0,
          imageUrl: '',
        });
        newImageGroup.patchValue({
          file: file,
          preview: e.target.result,
          isUploaded: false,
          uploadProgress: 0,
          isUploading: false,
          isError: false
        });
  
        this.housingImages.push(newImageGroup);
        this.uploadSingleImage(newImageGroup);
      };
      reader.readAsDataURL(file);
    }
    
    setTimeout(() => {
      input.value = '';
    }, 0);
  }

  uploadSingleImage(imgGroup: FormGroup): void {
    const file = imgGroup.value.file;
    if (!file) return;
  
    const formData = new FormData();
    formData.append('file', file);
  
    imgGroup.patchValue({ isUploading: true, isError: false, uploadProgress: 0 });
  
    this.apiService.uploadSingleFileForHousing("housing", Number.parseInt(sessionStorage.getItem('ActiveEmployerId') || '0', 10), formData).subscribe({
      next: (event: HttpEvent<any>) => {
        if (event.type === HttpEventType.UploadProgress && event.total) {
          const progress = Math.round((100 * event.loaded) / event.total);
          imgGroup.patchValue({ uploadProgress: progress });
        } else if (event.type === HttpEventType.Response) {
          imgGroup.patchValue({
            isUploading: false,
            isUploaded: true,
            imageUrl: event.body,
            preview: event.body // now the preview is the final uploaded URL
          });
        }
      },
      error: (err: HttpErrorResponse) => {
        console.error('Upload error', err);
        imgGroup.patchValue({ isUploading: false, isError: true });
      }
    });
  }
  

  deleteImage(index: number): void {
    const imgGroup = this.housingImages.at(index) as FormGroup;
    const currentValue = imgGroup.value;
  
    if (!currentValue.isUploaded) {
      // If not uploaded yet, just remove from the array
      this.housingImages.removeAt(index);
    } else if (currentValue.deleted) {
      // If currently deleted, restoring it
      if (this.undeletedCount >= this.maxFiles) {
        this.openInfoModal(`You have reached the maximum of ${this.maxFiles} images.`);
      } else {
        imgGroup.patchValue({ deleted: false });
      }
    } else {
      // Mark as deleted
      imgGroup.patchValue({ deleted: true });
    }
  }

  private convertToUTC(date: any): Date | null {
    if (!date) {
      return null; // Handle null or undefined values gracefully
    }
  
    // Ensure `date` is a Date object
    const validDate = date instanceof Date ? date : new Date(date);
  
    if (isNaN(validDate.getTime())) {
      throw new Error('Invalid date provided'); // Handle invalid date formats
    }
  
    // Convert to UTC without time zone offset
    const utcDate = new Date(Date.UTC(validDate.getFullYear(), validDate.getMonth(), validDate.getDate()));
    return utcDate;
  }
  
    
  get housingImages(): FormArray<FormGroup> {
    return this.jobForm.get('housingImages') as FormArray<FormGroup>;
  }  

  get undeletedCount(): number {
    return this.housingImages.controls
      .filter((ctrl) => !(ctrl.value.deleted))
      .length;
  }
  

  // Getter to determine if housing is provided
  get isHousingProvided(): boolean {
    return this.jobForm.get('employerProvidesHousing').value === true;
  }

  // Getter to determine if transportation is provided
  get isTransportationProvided(): boolean {
    return this.jobForm.get('employerProvidesTransportation').value === true;
  }
}
