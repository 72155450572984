<!-- Check if customer is available and if data is still loading -->
<div class="container" *ngIf="!employerContactSubscription && auth.isLoading$ | async; else loaded">
  <app-loading></app-loading>
</div>

<ng-template #loaded>
  <mat-card class="profile-data-mat-card" *ngIf="auth.user$ | async as user" [@fadeIn]="fadeIn">
    <!-- User Profile Info -->
    <mat-card-header>
      <mat-card-title>Cancel Subscription</mat-card-title>
    </mat-card-header>
    <br />
    <mat-card-content *ngIf="employerContactSubscription">
      <div *ngIf="this.employerContactSubscription.contactPermissions.employerAdmin == false" [@fadeIn]="fadeIn">
        <p> This is not a place for you please return to the <a href="/"> home page</a>!</p>
      </div>
      <div *ngIf="!employerContactSubscription && userType === 'E'"><app-loading></app-loading></div>
      Thinking of cancelling? We recommend switching to a lower tier plan so you can:
      <ul>
        <li>Actively stay in the platform, even when recruiting efforts have slowed down. Recruiting truly never stops,
          so staying in the Basic tier will allow you to stay connected with other employers and workers year-round.
          Employers are connecting with opposite-season organizations for recruiting throughout the course of the year.
        </li>

        <li>Keep a job posted so workers looking at employers in the platform to determine options for places to work
          next season know your organization will be hiring.</li>

        <li>Attend Seasonal Connect virtual and in-person events.</li>

        <li>Keep your information in the platform so you do not need to create a new profile like you would if you
          cancelled and returned.</li>
      </ul>
      <div *ngIf="employerContactSubscription" class="center">
        <button mat-raised-button class="light-blue" [routerLink]="['/change-subscription']" [@fadeIn]="fadeIn">Change
          My Plan</button>
      </div>

      <p class="center center-title">
        <b>If you wish to cancel your subscription, please call a member of our team at (617)716-6491, or contact us at <a [href]="'mailto:customercare@seasonalconnect.com?subject=Cancellation%20Request%20By%20' 
        + (employerContactSubscription.employer.dba ? employerContactSubscription.employer.dba : 'Employer%20Name') 
        + '&body=Hi%20Seasonal%20Connect'  
        +',%0A%0AI%20am%20writing%20to%20request%20the%20cancellation%20of%20Seasonal%20Connect%20subscription.%20This%20request%20is%20being%20made%20in%20accordance%20with%20the%20terms%20and%20conditions%20that%20were%20agreed%20upon.%0A%0APlease%20consider%20this%20email%20as%20a%20formal%20request%20for%20cancellation of ' 
        + (employerContactSubscription.employer.dba ? employerContactSubscription.employer.dba : 'Employer%20Name') 
        + '%20subscription. %0A%0ASincerely,%0A' 
        + (user.name ? user.name : 'Your Name')" target="_blank">customercare&#64;seasonalconnect.com </a></b>
      </p>

      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
        <!-- Statement and Payment Method buttons -->
        <div class="actions" fxFlex="33">
          <button mat-raised-button class="submit-button" [routerLink]="['/']">Back</button>
      </div>
        <div class="actions" fxFlex="33">
          <!-- <div *ngIf="employerContactSubscription" class="center">
            <button mat-raised-button color="warn" (click)="openConfirmationDialog()" [@fadeIn]="fadeIn">Cancel My
              Subscription</button>
          </div> -->
            
        </div>
        <div class="actions" fxFlex="33">

        </div>

      </div>
    </mat-card-content>
  </mat-card>
  <br />
</ng-template>