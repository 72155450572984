import { Component } from '@angular/core';
import { AuthService, User } from '@auth0/auth0-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../../api.service';
import { SharedDataService } from 'src/app/shared-data-service.service';
import { EMPTY, Observable, Subject, catchError, concatMap, filter, forkJoin, from, map, of, startWith, switchMap, take, takeUntil } from 'rxjs';
import { CancelReport, Customer, Invoice, Package } from 'src/app/models/ChargeOverModels';
import { ErrorModalComponent } from 'src/app/error-modal/error-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { EmployerContactSubscriptionDTO } from 'src/app/models/AccountManagementModels';
import { trigger, transition, useAnimation } from '@angular/animations';
import { bounceIn, fadeIn } from 'ng-animate';
import { faDownload, faCashRegister } from '@fortawesome/free-solid-svg-icons';
import { InfoModalComponent } from 'src/app/info-modal/info-modal.component';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { JobService } from '../../../services/Job.service';
import { JobPosition } from 'src/app/models/JobModels';
import { ConfirmRestoreDialogComponent } from 'src/app/confirm-restore-dialog/confirm-restore-dialog.component';



@Component({
  selector: 'app-job-postings',
  templateUrl: './job-postings.component.html',
  styleUrls: ['./job-postings.component.css'],
  animations: [trigger('bounceIn', [transition('* => *', useAnimation(bounceIn))]), trigger('fadeIn', [transition('* => *', useAnimation(fadeIn))])],
})
export class JobPostingsComponent {
  unsubscribe$ = new Subject<void>();

  jobs: any[] = [];
  selectedPosition: any = null;
  jobForm: FormGroup;
  seasons: string[] = [];
  jobTypeList: string[] = [];
  educationOptions: string[] = [];
  today = new Date();
  editIndex: number | null = null;
  previousQuestionValues: { [key: number]: any } = {};
  editQuestionControl = new FormControl('');
  newQuestionControl = new FormControl('');
  newQuestionGroup:  any;
  currentJobId: any;
  selectedJob: JobPosition; 
  hasSavedData: boolean = false;
  currentJobTitle: string = '';
  isPublished: boolean = false;
  
  isLoading: boolean = true;


  customer: Customer;
  package: Package;
  invoices: Invoice[];
  cancelReport: CancelReport
  downgradeReport: CancelReport
  employerContactSubscription: EmployerContactSubscriptionDTO;
  user: User;
  userType: string;
  portalURL: string;
  displayedColumns: string[] = ['invoice_id', 'date', 'due_date', 'invoice_status_name', 'total', 'payments', 'balance', 'actions'];
  isSmallScreen: boolean = false;
  bounceIn: any;
  fadeIn: any;
  faDownload = faDownload;
  faCashRegister = faCashRegister;
  chargeoverProfileErrorOpened: boolean = false;
  constructor(private fb: FormBuilder,
    public auth: AuthService,
    private sharedDataService: SharedDataService,
    private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private jobService: JobService
  ) { }


  ngOnInit() {

    this.fetchJobs();
    this.fetchSeasons();
    this.fetchJobTypes();
    this.fetchEducationOptions();


    this.jobForm = this.fb.group({
      employerPositionId:[null],
      published:[false],
      postDate: [null, Validators.required],
      removalDate: [null, [Validators.required, this.removalDateValidator.bind(this)]],
      season: [null, Validators.required],
      startDate: [null, Validators.required],
      endDate: [null, [Validators.required, this.endDateValidator.bind(this)]],
      jobTypes: this.fb.array([], this.minSelectedCheckboxes(1)),
      jobTitle: [null, Validators.required],
      jobDescription: [null, Validators.required],
      skills: [null],
      openings: [null, [Validators.min(1)]],
      manualLabor: [null],
      supervisesOthers: [null],
      trainingRequired: [null],
      onTheJobTraining: [null],
      education: [null],
      experience: [null, Validators.required],
      certifications: [null],
      drugScreening: [null],
      backgroundCheck: [null],
      ownCar: [null],
      driversLicense: [null],
      covidTest: [null],
      covidVaccine: [null],
      american: [false],
      j1: [false],
      inCountryH2B: [false],
      outOfCountryH2B: [false],
      hoursPerWeek: [null],
      wageMin: [null],
      wageMax: [null],
      overtimePay: [false, Validators.required],
      pieceWorkPay: [false, Validators.required],
      gratuities: [false, Validators.required],
      bonus: [false, Validators.required],
      otherCompensation: [null],
      healthBenefits: [false],
      dentalBenefits: [false],
      retentionBonus: [false],
      referralBonus: [false],
      offer401k: [false],
      paidTimeOff: [false],
      otherBenefits: [null],
      employerProvidesHousing: [null],
      employerOwned: [null],
      employerHousingAmount: [null],
      workerHousingAmount: [null],
      deposit: [null],
      utilitiesIncluded: [null],
      onProperty: [false],
      offProperty: [false],
      shared: [false],
      individual: [false],
      house: [false],
      apartment: [false],
      dormitory: [false],
      otherHousing: [null],
      employerProvidesTransportation: [null],
      publicTransportation: [false],
      shuttle: [false],
      car: [false],
      bicycle: [false],
      carpool: [false],
      otherTransport: [null],
      transportCost: [null],
      applicationQuestions: this.fb.array([])

    });

    this.newQuestionGroup = this.fb.group({
      text: ['', Validators.required],
      required: [false]
    });
  

    this.checkForEdit();
    this.checkForSavedData();
    this.autoSaveForm();

    this.jobForm.get('removalDate').valueChanges.subscribe(() => {
      this.jobForm.updateValueAndValidity();
    });


    this.auth.user$
      .pipe(
        // Remove null or undefined values
        filter(user => !!user && !!user.nickname),

        // Get the primary employer for the contact
        switchMap(user => {
          const arrMeta = user.nickname.split(':');
          const employerContactId = Number.parseInt(arrMeta[1]);
          this.userType = arrMeta[0];
          this.portalURL = this.sharedDataService.getData('portalURL');
          if (this.userType === 'E') {
            return this.apiService.getPrimaryEmployerForContact(employerContactId);
          }
          if (this.userType === 'S') {
            this.portalURL = this.sharedDataService.getData('portalURL');
            if (!this.portalURL) {
              this.apiService.GetConfigurationParam("PortalURL").subscribe(res => {
                this.portalURL = res;
                this.sharedDataService.setData<string>('portalURL', res);
                window.location.href = this.portalURL;
              });
            }
            else {
              window.location.href = this.portalURL;
            }
          }
          return EMPTY;
        }),

        // Handle errors in the pipeline
        catchError(e => {
          console.error(e);
          this.openErrorModal(e);
          this.router.navigate(['/error']);
          return EMPTY;
        }),

        // Unsubscribe when component is destroyed
        takeUntil(this.unsubscribe$)
      ).subscribe(employerContactSubscription => {

        this.employerContactSubscription = employerContactSubscription;

        this.apiService.getChargeoverProfile(this.employerContactSubscription.employer.employerId)
          .pipe(
            concatMap(customer => {
              this.customer = customer;

              // Handle getChargeoverSubscription separately
              return this.apiService.getChargeoverSubscription(this.employerContactSubscription.employer.employerId).pipe(
                catchError(subscriptionError => {
                  // Custom error handling for getChargeoverSubscription errors
                  console.error('Subscription error:', subscriptionError);
                  if (subscriptionError.error.errorCode === 2) {
                    this.router.navigate(['/buy-subscription'])
                  }
                  else {
                    this.openErrorModal(subscriptionError);
                  }
                  return EMPTY;
                }))})).subscribe();

      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get isHousingProvided(): boolean {
    return this.jobForm.get('employerProvidesHousing').value === true;
  }

  get isTransportationProvided(): boolean {
    return this.jobForm.get('employerProvidesTransportation').value === true;
  }

  fetchSeasons() {
    // Simulating an API call with mock data
    setTimeout(() => {
      this.seasons = ['Winter 2024-2025', 'Spring 2025', 'Summer 2025', 'Fall 2025'];
    }, 1500);
  }

  fetchJobTypes() {
    // Simulating an API call with mock data
    setTimeout(() => {
      this.jobTypeList = [
        "Server",
        "Bartender",
        "Busser",
        "Barista",
        "Food Runner",
        "Host/Hostess",
        "Server Assistant",
        "Dining Room Supervisor",
        "Cook",
        "Dishwasher",
        "Housekeeper",
        "Housekeeping Supervisor",
        "Groundskeeper Landscaper",
        "Golf Course Maintenance",
        "Lift Operator",
        "Ski Instructor",
        "Snow Maker",
        "Front Desk Agent",
        "Beach/Pool Attendant"
      ];
      
      this.jobTypeList.forEach(() => this.jobTypes.push(this.fb.control(false)));
    }, 200);
  }

  fetchEducationOptions() {
    setTimeout(() => {
      this.educationOptions = [
        'None',
        'High School/GED',
        'Bachelor’s Degree or equivalent',
        'Graduate degree or equivalent',
      ];
    }, 100);
  }  

  get jobTypes(): FormArray {
    return this.jobForm.get('jobTypes') as FormArray;
  }

  updateJobTypesCheckboxes(selectedJobTypes: string[]) {
    // Ensure that the FormArray is initialized
    if (this.jobTypes.length === 0) {
      // If not initialized yet, initialize it
      this.jobTypeList.forEach(() => this.jobTypes.push(this.fb.control(false)));
    }
  
    this.jobTypes.controls.forEach((control, index) => {
      const jobType = this.jobTypeList[index];
      const isSelected = selectedJobTypes.includes(jobType);
      control.setValue(isSelected);
    });
  }


  loadJobs() {
    this.jobService.fetchJobs().subscribe((jobs) => {
      this.jobs = jobs;
    });
  }

  checkForEdit() {
    this.route.queryParams.subscribe((params) => {
      if (params['EmployerPositionId']) {
        this.currentJobId = +params['EmployerPositionId'];
        this.loadJob(this.currentJobId);
      } else {
        // If no EmployerPositionId in params, reset current job info
        this.currentJobId = null;
        this.currentJobTitle = '';
        this.isPublished = false;
      }
    });
  }

  loadJob(id: number) {
    this.jobService.getJobById(id).subscribe((job) => {
      if (job) {
        this.jobForm.patchValue(job);
  
        if (job.jobTypes && Array.isArray(job.jobTypes)) {
          this.updateJobTypesCheckboxes(job.jobTypes || []);
        }
  
        // Populate applicationQuestions FormArray
        if (job.applicationQuestions && Array.isArray(job.applicationQuestions)) {
          this.populateApplicationQuestions(job.applicationQuestions);
        }
        this.currentJobTitle = job.jobTitle;
        this.isPublished = job.published || false; 
      } else {
        alert('Job not found.');
        this.currentJobTitle = '';
        this.isPublished = false;
      }
    });
  }
  
 
  editJob(job: JobPosition) {
    if (this.jobForm.dirty && !this.jobForm.pristine) {
      if (!confirm('You have unsaved changes. Do you want to discard them?')) {
        return;
      } else {
        localStorage.removeItem('jobForm');
      }
    }
    this.router.navigate([], {
      queryParams: { EmployerPositionId: job.employerPositionId },
    });
  }

  importPosition() {
    if (this.selectedJob) {
      this.jobForm.patchValue(this.selectedJob);
      this.currentJobTitle = this.selectedJob.jobTitle;
      this.isPublished = this.selectedJob.published || false; 
    } else {
      alert('Please select a position to import.');
    }
  }


 
  checkForSavedData() {
    const savedForm = localStorage.getItem('jobForm');
    if (savedForm) {
      const formDataWithTimestamp = JSON.parse(savedForm);
      const formData = formDataWithTimestamp.data;
      const hasNonEmptyFields = this.hasNonEmptyFields(formData);
      const now = new Date().getTime();
      const expiryTime = 72 * 60 * 60 * 1000; // 1 hour in milliseconds
      if (hasNonEmptyFields && (now - formDataWithTimestamp.timestamp < expiryTime)) {
        this.hasSavedData = true;
      } else {
        localStorage.removeItem('jobForm');
        this.hasSavedData = false;
      }
    } else {
      this.hasSavedData = false;
    }
  }

  restoreSavedData() {
    const savedForm = localStorage.getItem('jobForm');
    if (savedForm) {
      const formDataWithTimestamp = JSON.parse(savedForm);
      const savedData = formDataWithTimestamp.data;
      const currentData = this.jobForm.value;
  
      // Find differences between current data and saved data
      const differences = this.getDifferences(currentData, savedData);
  
      if (differences.length === 0) {
        // No differences, proceed to restore
        this.jobForm.patchValue(savedData);
        alert('Draft restored from local storage.');
        this.updateCurrentJobInfo();
      } else {
        // Open confirmation dialog
        const dialogRef = this.dialog.open(ConfirmRestoreDialogComponent, {
          width: '400px',
          data: { differences },
        });
  
        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            // User confirmed, restore data
            this.jobForm.patchValue(savedData);
            alert('Draft restored from local storage.');
            this.updateCurrentJobInfo();
          } else {
            // User canceled, do nothing
          }
        });
      }
    } else {
      alert('No saved draft found.');
      this.hasSavedData = false;
    }
  }
  

  clearSavedData()
  {
    this.jobForm.reset();
    localStorage.removeItem('jobForm');
    this.hasSavedData = false; 

  }

  autoSaveForm() {
    this.jobForm.valueChanges.subscribe((val) => {
      const hasNonEmptyFields = this.hasNonEmptyFields(val);
      if (hasNonEmptyFields && this.jobForm.dirty) {
        const formDataWithTimestamp = {
          data: val,
          timestamp: new Date().getTime(),
        };
        localStorage.setItem('jobForm', JSON.stringify(formDataWithTimestamp));
      } 
    });
  }


  // Validator to ensure at least one job type is selected
  minSelectedCheckboxes(min = 1) {
    return (formArray: FormArray): Validators | null => {
      const totalSelected = formArray.controls
        .map(control => control.value)
        .reduce((prev, next) => next ? prev + 1 : prev, 0);

      return totalSelected >= min ? null : { required: true };
    };
  }

  fetchJobs() {
    this.jobService.fetchJobs().subscribe((jobs) => {
      this.jobs = jobs;
    });
  }



  openErrorModal(error: any, redirectToPaymentDetails: boolean = false): void {
    if (!this.chargeoverProfileErrorOpened) {
      this.chargeoverProfileErrorOpened == true;
      const dialogRef = this.dialog.open(ErrorModalComponent, {
        data: error,
        maxWidth: '70vw', // specify max width
        maxHeight: '80vh', // specify max height
        //height: '80vh' // specify height
      });

      // Optionally, you can subscribe to the afterClosed observable to perform actions after the modal is closed.
      dialogRef.afterClosed().subscribe(() => {
        this.chargeoverProfileErrorOpened = false;
        if (redirectToPaymentDetails) {
          this.router.navigate(['/signup-payment-details'], {
            queryParams: {
              Id: this.employerContactSubscription.employer.employerId
            }
          });
        }
      });
    }
  }


  hasNonEmptyFields(formData: any): boolean {
    for (let key in formData) {
      if (formData.hasOwnProperty(key)) {
        const value = formData[key];
        if (this.isNonEmpty(value)) {
          return true;
        }
      }
    }
    return false;
  }
  
  isNonEmpty(value: any): boolean {
    if (Array.isArray(value)) {
      // Check if any item in the array is non-empty
      return value.some((item) => this.isNonEmpty(item));
    } else if (typeof value === 'object' && value !== null) {
      // Check if any property in the object is non-empty
      return Object.values(value).some((propValue) => this.isNonEmpty(propValue));
    } else if (typeof value === 'boolean') {
      // Only 'true' is considered non-empty
      return value === true;
    } else {
      // Check for non-null, non-undefined, non-empty strings or numbers
      return value !== null && value !== undefined && value !== '';
    }
  }

  updateCurrentJobInfo() {
    this.currentJobTitle = this.jobForm.get('jobTitle').value || '';
    this.isPublished = this.jobForm.get('published').value || false;
  }
  getDifferences(currentData: any, savedData: any): string[] {
    const differences: string[] = [];
    for (const key in savedData) {
      if (savedData.hasOwnProperty(key)) {
        const currentValue = currentData[key];
        const savedValue = savedData[key];
        if (!this.valuesAreEqual(currentValue, savedValue)) {
          differences.push(this.getFieldLabel(key) + ': ' + this.getSavedValue(savedValue));
        }
      }
    }
    return differences;
  }
  
  valuesAreEqual(val1: any, val2: any): boolean {
    // Handle arrays
    if (Array.isArray(val1) && Array.isArray(val2)) {
      return JSON.stringify(val1) === JSON.stringify(val2);
    }
    // Handle objects
    if (typeof val1 === 'object' && typeof val2 === 'object') {
      return JSON.stringify(val1) === JSON.stringify(val2);
    }
    // Handle other types
    return val1 === val2;
  }

  getSavedValue(value: any): any {
    if (value === null) {
        return "Empty";
    }
    if (value === true) {
        return "Yes";
    }
    if (value === false) {
        return "No";
    }
    if (typeof value === "string" && /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/.test(value)) {
        // Convert ISO date string to US format (MM/DD/YYYY)
        const date = new Date(value);
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const year = date.getFullYear();
        return `${month}/${day}/${year}`;
    }
    return value;
}

  
  getFieldLabel(fieldName: string, customLabel?: string): string {
    // Map field names to user-friendly labels
    const fieldLabels: { [key: string]: string } = {
      employerPositionId: 'Employer Position ID',
      postDate: 'Post Date',
      removalDate: 'Removal Date',
      season: 'Season',
      startDate: 'Start Date',
      endDate: 'End Date',
      jobTypes: 'Job Types',
      jobTitle: 'Job Title',
      jobDescription: 'Job Description',
      skills: 'Skills',
      openings: 'Openings',
      // Add all other fields accordingly
    };

    if (customLabel) {
        return customLabel;
    }

    const formattedLabel = fieldLabels[fieldName] || 
      fieldName.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase());

    return formattedLabel.trim();
}

    
  
  openInfoModal(message: string): void {
    const dialogRef = this.dialog.open(InfoModalComponent, {
      data: { message: message },
      maxWidth: '70vw',
      maxHeight: '80vh',
    });

    dialogRef.afterClosed().subscribe(() => {

    });
  }
  // Getter for easy access to applicationQuestions FormArray
  get applicationQuestions(): FormArray {
    return this.jobForm.get('applicationQuestions') as FormArray;
  }


  populateApplicationQuestions(questionsData: any[]) {
    this.applicationQuestions.clear();
    questionsData.forEach((question) => {
      this.applicationQuestions.push(
        this.fb.group({
          text: [question.text || question, Validators.required],
          required: [question.required || false]
        })
      );
    });
  }
  
  // Add a new question to the FormArray
  addQuestion() {
    if (this.newQuestionGroup.valid) {
      this.applicationQuestions.push(
        this.fb.group({
          text: [this.newQuestionGroup.get('text')?.value.trim(), Validators.required],
          required: [this.newQuestionGroup.get('required')?.value]
        })
      );
      this.newQuestionGroup.reset({ text: '', required: false });
    }
  }
  

  editQuestion(index: number) {
    this.editIndex = index;
    const questionGroup = this.applicationQuestions.at(index) as FormGroup;
    this.previousQuestionValues[index] = { ...questionGroup.value };
  }
  

  saveEditedQuestion(index: number) {
    const questionGroup = this.applicationQuestions.at(index) as FormGroup;
    if (questionGroup.valid) {
      delete this.previousQuestionValues[index];
      this.editIndex = null;
    }
  }
  
  cancelEdit(index: number) {
    const questionGroup = this.applicationQuestions.at(index) as FormGroup;
    if (this.previousQuestionValues[index]) {
      questionGroup.setValue(this.previousQuestionValues[index]);
    }
    this.editIndex = null;
  }
  

  // Remove a question from the FormArray
  removeQuestion(index: number) {
    this.applicationQuestions.removeAt(index);
    if (this.editIndex === index) {
      // The edited question was removed
      this.cancelEdit(index);
    } else if (this.editIndex !== null && this.editIndex > index) {
      // Adjust editIndex because indices have shifted
      this.editIndex--;
    }
  }
  




  removalDateValidator(control: AbstractControl): ValidationErrors | null {
    const removalDateValue = control.value;
    const parent = control.parent;

    if (!parent) {
      return null;
    }

    const postDateValue = parent.get('postDate')?.value;

    if (!postDateValue || !removalDateValue) {
      return null;
    }

    const postDate = new Date(postDateValue);
    const removalDate = new Date(removalDateValue);

    return removalDate.getTime() > postDate.getTime() ? null : { dateRange: true };
  }

  endDateValidator(control: AbstractControl): ValidationErrors | null {
    const removalDateValue = control.value;
    const parent = control.parent;

    if (!parent) {
      return null;
    }

    const postDateValue = parent.get('startDate')?.value;

    if (!postDateValue || !removalDateValue) {
      return null;
    }

    const postDate = new Date(postDateValue);
    const removalDate = new Date(removalDateValue);

    return removalDate.getTime() > postDate.getTime() ? null : { dateRange: true };
  }

  saveAsDraft() {
    const val = this.jobForm.value;
    const hasNonEmptyFields = this.hasNonEmptyFields(val);
    if (hasNonEmptyFields) {
      const formDataWithTimestamp = {
        data: val,
        timestamp: new Date().getTime(),
      };
      localStorage.setItem('jobForm', JSON.stringify(formDataWithTimestamp));
      this.hasSavedData = true;
      alert('Draft saved locally.');
    } else {
      alert('Cannot save an empty form.');
    }
  }
  

  publish() {
    // Implement publish logic (e.g., send data to backend)
    localStorage.removeItem('jobForm');
    this.hasSavedData = false; // Update flag since saved data is cleared
    alert('Job published.');
  }

  onSubmit() {

  }

}
