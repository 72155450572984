<app-photo-header [slides]="bannerImages" [avatarUrl]="employer?.avatarUrl" [employerName]="employer?.dba"
  [tagline]="employer?.tagLine"></app-photo-header>

<div class="job-display-container" fxLayout="column">
  <!-- Top Header / Logo / Names -->


  <!-- Sub-navigation if needed -->
  <nav class="sub-nav" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px" *ngIf="portalURL && jobPosition">
    <a mat-button target="_blank"
      [href]="portalURL + '/Employers/EmployerProfile?viewpro=' + jobPosition?.employerId">About</a>
    <!-- <a mat-button target="_blank" removed for now due to legacy portal limitations
      [href]="portalURL + '/Employers/EmployerProfile?viewpro=' + jobPosition?.employerId + '#jobs'">Open Positions</a> -->
  </nav>

  <div *ngIf="isPositionLoading" fxLayout="row" fxLayoutAlign="center center">
    <mat-spinner diameter="80"></mat-spinner>
  </div>
  <!-- Main Content (Two Column Layout) -->
  <div class="main-content" fxLayout="row" fxLayoutGap="24px" *ngIf="jobPosition">
    <!-- LEFT COLUMN -->
    <div class="left-column" fxFlex="70" fxFlex.lt-md="100"  fxLayout="column" fxLayoutGap="16px">

      <!-- Job Title, Description -->
      <mat-card class="job-details">
        <h2>{{ jobPosition?.jobTitle }}</h2>
        <quill-view [content]="jobPosition?.jobDescription" format="html" theme="snow"></quill-view>
      </mat-card>

      <!-- Dates and Skills -->
      <mat-card class="job-info" fxLayout="row" fxLayoutGap="20px">
        <div fxFlex="50">
          <h4> Dates</h4>
          <p><mat-icon class="section-icon">event</mat-icon> {{ jobPosition?.postDate | date }} - {{
            jobPosition?.removalDate | date }}</p>
        </div>
        <div fxFlex="50" *ngIf="jobPosition?.skills">
          <h4> Skills & Competencies</h4>
          <p> {{ jobPosition?.skills }}</p>
        </div>
      </mat-card>

      <!-- Hours and Openings -->
      <mat-card class="job-info" fxLayout="row" fxLayoutGap="20px">
        <div fxFlex="50" *ngIf="jobPosition?.hoursPerWeek">
          <h4> Hours per Week</h4>
          <p><mat-icon class="section-icon">schedule</mat-icon> {{ jobPosition?.hoursPerWeek }}</p>
        </div>
        <div fxFlex="50" *ngIf="jobPosition?.openings">
          <h4> # of Openings</h4>
          <p><mat-icon class="section-icon">group</mat-icon> {{ jobPosition?.openings }}</p>
        </div>
      </mat-card>


      <!-- Wages & Compensation -->
      <mat-card class="wages-compensation" fxLayout="column" fxLayoutGap="10px">
        <h3> Wages & Compensation</h3>
        <div fxLayout="row" fxLayoutGap="20px">
          <div fxFlex="50">
            <h4> Hourly Wage</h4>
            <p> <mat-icon class="section-icon">payments</mat-icon> ${{ jobPosition?.wageMin }} - ${{
              jobPosition?.wageMax }} per hour</p>
          </div>
          <div fxFlex="50">
            <h4> Additional Compensation</h4>
            <p>
              {{ jobPosition?.overtimePay ? 'Overtime Pay' : '' }}
              {{ jobPosition?.pieceWorkPay ? ', Piece-work Pay' : '' }}
              {{ jobPosition?.gratuities ? ', Gratuities' : '' }}
              {{ jobPosition?.bonus ? ', Bonus' : '' }}
            </p>
            <div
            *ngIf="!(jobPosition?.overtimePay || jobPosition?.pieceWorkPay || jobPosition?.gratuities || jobPosition?.bonus)">
            No additional Compensation.
      
          </div>
          </div>
        </div>
        <h4> Other Compensation</h4>
        <p *ngIf="jobPosition?.otherCompensation"><mat-icon class="section-icon">card_giftcard</mat-icon> {{
          jobPosition?.otherCompensation }}</p>
        <p *ngIf="!jobPosition?.otherCompensation">No other compensation</p>

        <h4> Benefits</h4>
        <p *ngIf="jobPosition?.healthBenefits">
          <mat-icon class="section-icon">medical_services</mat-icon> Health Insurance
        </p>

        <p *ngIf="jobPosition?.dentalBenefits">
          <mat-icon class="section-icon">mood</mat-icon> Dental Insurance
        </p>

        <p *ngIf="jobPosition?.retentionBonus">
          <mat-icon class="section-icon">monetization_on</mat-icon> Retention Bonus
        </p>

        <p *ngIf="jobPosition?.referralBonus">
          <mat-icon class="section-icon">people_alt</mat-icon> Referral Bonus
        </p>

        <p *ngIf="jobPosition?.offer401k">
          <mat-icon class="section-icon">savings</mat-icon> 401(k) Retirement Plan
        </p>

        <p *ngIf="jobPosition?.paidTimeOff">
          <mat-icon class="section-icon">beach_access</mat-icon> Paid Time Off
        </p>
        <div
        *ngIf="!(jobPosition?.paidTimeOff || jobPosition?.offer401k || jobPosition?.referralBonus || jobPosition?.retentionBonus || jobPosition?.dentalBenefits || jobPosition?.healthBenefits)">
        No additional Benefits.
  
      </div>

        <div class="icons-section" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="32px">
          <!-- Housing Icon and Text -->
          <div *ngIf="jobPosition?.employerProvidesHousing" fxLayout="column" fxLayoutAlign="center center">
            <button mat-icon-button class="large-button" (click)="openHousingModal()">
              <mat-icon class="icons-job-display large-icon">home</mat-icon>
            </button>
            <p>Offers Housing</p>
          </div>

          <!-- Transportation Icon and Text -->
          <div *ngIf="jobPosition?.employerProvidesTransportation" fxLayout="column" fxLayoutAlign="center center">
            <button mat-icon-button class="large-button" (click)="openTransportationModal()">
              <mat-icon class="icons-job-display large-icon">directions_bus</mat-icon>
            </button>
            <p>Offers Transportation</p>
          </div>
        </div>


      </mat-card>

      <!-- Qualifications, Requirements, Other Info, etc. -->
      <mat-card class="qualifications-info">
        <div fxLayout="row" fxLayoutGap="20px">
          <div fxFlex="50">
        <h4>Qualifications</h4>
        <div
          *ngIf="jobPosition?.american || jobPosition?.j1 || jobPosition?.inCountryH2B || jobPosition?.outOfCountryH2B">
          Visa Types Accepted:
          <p *ngIf="jobPosition?.american"><mat-icon class="section-icon">flag</mat-icon> American</p>
          <p *ngIf="jobPosition?.j1"><mat-icon class="section-icon">public</mat-icon> J1</p>
          <p *ngIf="jobPosition?.inCountryH2B"><mat-icon class="section-icon">home_work</mat-icon> In-Country H2B</p>
          <p *ngIf="jobPosition?.outOfCountryH2B"><mat-icon class="section-icon">south_america</mat-icon> Out-of-Country
            H2B</p>
        </div>
        <div *ngIf="jobPosition?.education">
          Minimum Education:
          <p><mat-icon class="section-icon">school</mat-icon> {{ jobPosition?.education }}</p>
        </div>

        <div *ngIf="jobPosition?.experience">
          Minimum Experience:
          <p><mat-icon class="section-icon">work_history</mat-icon> {{ jobPosition?.experience }}</p>
        </div>
        <div *ngIf="jobPosition?.certifications">
          Certifications:
          <p><mat-icon class="section-icon">badge</mat-icon> {{ jobPosition?.certifications }}</p>
        </div>

        <div
        *ngIf="!(jobPosition?.american || jobPosition?.j1 || jobPosition?.inCountryH2B || jobPosition?.outOfCountryH2B || jobPosition?.certifications || jobPosition?.experience || jobPosition?.education)">
        No additional Qualifications.
  
      </div>
      </div>

          <div fxFlex="50">
        <h4>Requirements</h4>
        <div
          *ngIf="jobPosition?.drugScreening || jobPosition?.backgroundCheck || jobPosition?.ownCar || jobPosition?.driversLicense || jobPosition?.covidTest || jobPosition?.covidVaccine">
          <p *ngIf="jobPosition?.drugScreening">
            <mat-icon class="section-icon">science</mat-icon> Drug Screening
          </p>

          <p *ngIf="jobPosition?.backgroundCheck">
            <mat-icon class="section-icon">policy</mat-icon> Background Check
          </p>

          <p *ngIf="jobPosition?.ownCar">
            <mat-icon class="section-icon">directions_car</mat-icon> Own Car
          </p>

          <p *ngIf="jobPosition?.driversLicense">
            <mat-icon class="section-icon">credit_card</mat-icon> Driver's License
          </p>

          <p *ngIf="jobPosition?.covidTest">
            <mat-icon class="section-icon">coronavirus</mat-icon> COVID Test
          </p>

          <p *ngIf="jobPosition?.covidVaccine">
            <mat-icon class="section-icon">vaccines</mat-icon> COVID Vaccine
          </p>

        </div>
        <p
          *ngIf="!(jobPosition?.drugScreening || jobPosition?.backgroundCheck || jobPosition?.ownCar || jobPosition?.driversLicense || jobPosition?.covidTest || jobPosition?.covidVaccine)">
          No additional requirements.
        </p>


        <h4>Other Information</h4>
        <div
          *ngIf="jobPosition?.manualLabor || jobPosition?.supervisesOthers || jobPosition?.trainingRequired || jobPosition?.onTheJobTraining">

          <p *ngIf="jobPosition?.manualLabor"><mat-icon class="section-icon">info</mat-icon> Manual labor is required.
          </p>
          <p *ngIf="jobPosition?.supervisesOthers"><mat-icon class="section-icon">info</mat-icon> This position
            supervises others.</p>
          <p *ngIf="jobPosition?.trainingRequired"><mat-icon class="section-icon">info</mat-icon> Training is required.
          </p>
          <p *ngIf="jobPosition?.onTheJobTraining"><mat-icon class="section-icon">info</mat-icon> On-the-job training is
            provided.</p>
        </div>

        <p
          *ngIf="!(jobPosition?.manualLabor || jobPosition?.supervisesOthers || jobPosition?.trainingRequired || jobPosition?.onTheJobTraining)">
          No additional information available.
        </p>
      </div>
    </div>
      </mat-card>
    </div>
    <!-- END LEFT COLUMN -->

    <!-- RIGHT COLUMN -->
    <div class="right-column" fxFlex="30" fxFlex.lt-md="100" fxLayout="column" fxLayoutGap="16px">

      <!-- Action Buttons at top if desired -->
      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px" *ngIf="(auth.isAuthenticated$ | async) === true">
        <a mat-raised-button color="primary" class="apply-button" target="_blank"
          [href]="portalURL + '/Employers/EmployerPositionApplication?viewpro=' + jobPosition?.employerPositionId">Apply</a>
      </div>
      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px" *ngIf="(auth.isAuthenticated$ | async) === false">
        <a mat-raised-button color="primary" class="apply-button"
        [routerLink]="'/signup-worker'">Signup to Apply</a>
      </div>

      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">

        <!-- Bookmark Button -->
        <button mat-icon-button *ngIf="bookmarkPositionStatus"
          [disabled]="bookmarkPositionStatus?.bookmarksDisabled || isBookmarkLoading" (click)="toggleBookmark()"
          aria-label="Bookmark this job">
          <mat-icon *ngIf="!isBookmarkLoading">
            {{ bookmarkPositionStatus.bookmarked ? 'bookmark' : 'bookmark_border' }}
          </mat-icon>

          <!-- Replace mat-icon with mat-spinner or a small spinner if loading -->
          <mat-spinner *ngIf="isBookmarkLoading" diameter="22"></mat-spinner>
        </button>

        <!-- Hide Button -->
        <button mat-icon-button *ngIf="bookmarkPositionStatus"
          [disabled]="bookmarkPositionStatus?.bookmarksDisabled || isHideLoading" (click)="toggleVisibility()"
          aria-label="Hide this job">
          <mat-icon class="hide-icon" *ngIf="!isHideLoading">
            {{ bookmarkPositionStatus.hidden ? 'visibility' : 'visibility_off' }}
          </mat-icon>
          <mat-spinner *ngIf="isHideLoading" diameter="22"></mat-spinner>
        </button>


      </div>


      <!-- MAP or Employer Info or both -->
      <mat-card class="employer-map">
        <h3>Location</h3>
        <!-- Insert your map component or iframe here -->
        <div class="map-placeholder">
          <iframe *ngIf="googleMapUrl" [src]="googleMapUrl" width="300" height="200" frameborder="0" style="border:0"
            allowfullscreen></iframe>
        </div>
      </mat-card>

      <!-- Employer Details -->
      <!-- Employer Details -->
      <mat-card *ngIf="employer" class="employer-details" fxLayout="column" fxLayoutGap="12px">
        <h3>{{ employer?.dba }}</h3>
        <span>{{ employer?.address }}</span>
        <span>{{ employer?.city }}, {{ employer?.state }} {{ employer?.zip }}</span>
        <span *ngIf="employer?.telephone">
          <mat-icon class="contact-icon">phone</mat-icon> {{ employer?.telephone }}
        </span>
        <span *ngIf="employer?.webSite">
          <mat-icon class="contact-icon">language</mat-icon>
          <a [href]="employer?.webSite" target="_blank" rel="noopener noreferrer">Website</a>
        </span>
        <span *ngIf="employer?.numPermStaff">
          <mat-icon class="contact-icon">people</mat-icon> {{ employer?.numPermStaff }} Permanent Staff
        </span>
        <span *ngIf="employer?.numSeasonalStaff">
          <mat-icon class="contact-icon">people_outline</mat-icon> {{ employer?.numSeasonalStaff }} Seasonal Staff
        </span>
      </mat-card>

      <!-- Employer Contacts -->
      <div *ngIf="jobPosition?.employerContacts?.length" class="employer-contacts">
        <h2>Contacts</h2>
        <mat-card *ngFor="let contact of jobPosition.employerContacts" class="employer-contact-card">
          <h3>{{ contact.name }}</h3>
          <div *ngIf="contact.phone" class="contact-info">
            <mat-icon class="contact-icon">phone</mat-icon>
            <span>{{ contact.phone }}</span>
          </div>
          <div *ngIf="contact.email" class="contact-info">
            <mat-icon class="contact-icon">email</mat-icon>
            <a [href]="'mailto:' + contact.email">{{ contact.email }}</a>
          </div>
        </mat-card>
      </div>



    </div>
    <!-- END RIGHT COLUMN -->
  </div>
  <!-- END Main Content -->
</div>

<!-- Housing Details Dialog -->
<ng-template #housingDetails>
  <h1 mat-dialog-title>Housing Details</h1>
  <mat-dialog-content>
    <p>Location:
      <span *ngIf="jobPosition?.onProperty">On Property</span>
      <span *ngIf="jobPosition?.offProperty">Off Property</span>
    </p>
    <p>Shared: {{ jobPosition?.shared ? 'Yes' : 'No' }}</p>
    <p>Individual: {{ jobPosition?.individual ? 'Yes' : 'No' }}</p>
    <p>Housing Type:
      <span *ngIf="jobPosition?.house">House</span>
      <span *ngIf="jobPosition?.apartment">Apartment</span>
      <span *ngIf="jobPosition?.dormitory">Dormitory</span>
      <span *ngIf="jobPosition?.otherHousing">Other: {{ jobPosition?.otherHousing }}</span>
    </p>
    <p>Employer Provides Housing: {{ jobPosition?.employerProvidesHousing ? 'Yes' : 'No' }}</p>
    <p>Employer Owned: {{ jobPosition?.employerOwned ? 'Yes' : 'No' }}</p>
    <p>Employer Paid: {{ jobPosition?.employerHousingAmount ? ('$' + jobPosition?.employerHousingAmount + ' / week') :
      'Not Provided' }}</p>
    <p>Employee Paid: {{ jobPosition?.workerHousingAmount ? ('$' + jobPosition?.workerHousingAmount + ' / week') : 'Not
      Provided' }}</p>
    <p>Deposit: {{ jobPosition?.deposit ? ('$' + jobPosition?.deposit) : 'No Deposit Required' }}</p>
    <p>Utilities Included: {{ jobPosition?.utilitiesIncluded ? 'Yes' : 'No' }}</p>

    <h3 *ngIf="jobPosition?.housingImages && jobPosition?.housingImages.length"> Housing Images</h3>
    <div class="housing-image-grid">
      <div *ngFor="let image of jobPosition?.housingImages" class="housing-image-item" (click)="openImageModal(image)">
        <img [src]="image.imageUrl" [alt]="image.photoCaption || 'Housing Image'" />
      </div>
    </div>


  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-raised-button (click)="closeHousingModal()" color="primary">Close</button>
  </mat-dialog-actions>
</ng-template>


<ng-template #transportationDetails>
  <h1 mat-dialog-title>Transportation Details</h1>
  <mat-dialog-content>
    <p>Employer Provides Transportation: {{ jobPosition?.employerProvidesTransportation ? 'Yes' : 'No' }}</p>
    <p>Available Transportation Options:</p>
    <ul>
      <li *ngIf="jobPosition?.publicTransportation">Public Transportation</li>
      <li *ngIf="jobPosition?.shuttle">Shuttle</li>
      <li *ngIf="jobPosition?.car">Car</li>
      <li *ngIf="jobPosition?.bicycle">Bicycle</li>
      <li *ngIf="jobPosition?.carpool">Carpool</li>
      <li *ngIf="jobPosition?.otherTransport">Other: {{ jobPosition?.otherTransport }}</li>
    </ul>
    <p>Transportation Cost:
      {{ jobPosition?.transportCost ? ('$' + jobPosition?.transportCost + ' / week') : 'No Cost Provided' }}
    </p>


  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-raised-button (click)="closeTransportationModal()" color="primary">Close</button>
  </mat-dialog-actions>
</ng-template>