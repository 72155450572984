// job.service.ts
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { JobPosition } from '../models/JobModels';

@Injectable({
  providedIn: 'root',
})
export class JobService {
  private jobs: JobPosition[] = [
    {
      employerPositionId: 1,
      published: true,
      postDate: new Date('2023-01-01'),
      removalDate: new Date('2023-02-01'),
      season: 'Winter 2024-2025',
      startDate: new Date('2023-06-01'),
      endDate: new Date('2023-08-31'),
      jobTypes: ['Housekeeping'],
      jobTitle: 'Housekeeper',
      jobDescription: 'Keep house',
      skills: 'Cleaning',
      openings: 3,
      manualLabor: false,
      supervisesOthers: false,
      trainingRequired: true,
      onTheJobTraining: true,
      education: 'Doctorate',
      experience: 24,
      certifications: 'None',
      drugScreening: false,
      backgroundCheck: true,
      ownCar: false,
      driversLicense: true,
      covidTest: false,
      covidVaccine: true,
      american: true,
      h2b: false,
      j1: false,
      inCountry: true,
      outOfCountry: false,
      hoursPerWeek: 40,
      wageMin: 70000,
      wageMax: 90000,
      overtimePay: 0,
      pieceWorkPay: 0,
      gratuities: 0,
      bonus: 5000,
      otherCompensation: 'Stock options',
      healthBenefits: true,
      dentalBenefits: true,
      retentionBonus: false,
      referralBonus: true,
      offer401k: true,
      paidTimeOff: true,
      otherBenefits: 'Gym membership',
      employerProvidesHousing: true,
      employerOwned: null,
      employerHousingAmount: null,
      workerHousingAmount: null,
      deposit: null,
      utilitiesIncluded: null,
      onProperty: null,
      offProperty: null,
      shared: null,
      individual: null,
      house: null,
      apartment: null,
      dormitory: null,
      otherHousing: null,
      employerProvidesTransportation: false,
      publicTransportation: false,
      shuttle: false,
      car: false,
      bicycle: false,
      carpool: false,
      otherTransport: null,
      transportCost: null,
      applicationQuestions: [],
    },
    {
      employerPositionId: 2,
      published: false,
      postDate: new Date('2023-03-15'),
      removalDate: new Date('2023-04-15'),
      season: 'Summer 2025',
      startDate: new Date('2023-12-01'),
      endDate: new Date('2024-02-28'),
      jobTypes: ['Guest Services'],
      jobTitle: 'Front of the house',
      jobDescription: 'Do front of the house jobs',
      skills: 'Front, Desk',
      openings: 2,
      manualLabor: false,
      supervisesOthers: false,
      trainingRequired: true,
      onTheJobTraining: false,
      education: 'Bachelor’s Degree',
      experience: 12,
      certifications: 'Certified Front Desk',
      drugScreening: false,
      backgroundCheck: true,
      ownCar: false,
      driversLicense: false,
      covidTest: false,
      covidVaccine: true,
      american: true,
      h2b: false,
      j1: false,
      inCountry: true,
      outOfCountry: false,
      hoursPerWeek: 20,
      wageMin: 30000,
      wageMax: 40000,
      overtimePay: 0,
      pieceWorkPay: 0,
      gratuities: 0,
      bonus: 2000,
      otherCompensation: null,
      healthBenefits: false,
      dentalBenefits: false,
      retentionBonus: false,
      referralBonus: false,
      offer401k: false,
      paidTimeOff: false,
      otherBenefits: null,
      employerProvidesHousing: false,
      employerOwned: null,
      employerHousingAmount: null,
      workerHousingAmount: null,
      deposit: null,
      utilitiesIncluded: null,
      onProperty: null,
      offProperty: null,
      shared: null,
      individual: null,
      house: null,
      apartment: null,
      dormitory: null,
      otherHousing: null,
      employerProvidesTransportation: true,
      publicTransportation: true,
      shuttle: false,
      car: false,
      bicycle: false,
      carpool: false,
      otherTransport: null,
      transportCost: 0,
      applicationQuestions: [],
    },
    {
      employerPositionId: 3,
      published: true,
      postDate: new Date('2023-05-10'),
      removalDate: new Date('2023-06-10'),
      season: 'Summer 2025',
      startDate: new Date('2023-04-01'),
      endDate: new Date('2023-06-30'),
      jobTypes: ['Retail'],
      jobTitle: 'Marketing Intern',
      jobDescription: 'Assist with marketing campaigns and social media.',
      skills: 'Content Creation, Social Media, SEO',
      openings: 1,
      manualLabor: false,
      supervisesOthers: false,
      trainingRequired: true,
      onTheJobTraining: true,
      education: 'Masters’s Degree',
      experience: null,
      certifications: null,
      drugScreening: false,
      backgroundCheck: false,
      ownCar: false,
      driversLicense: false,
      covidTest: false,
      covidVaccine: true,
      american: true,
      h2b: false,
      j1: false,
      inCountry: true,
      outOfCountry: false,
      hoursPerWeek: 30,
      wageMin: 0,
      wageMax: 0,
      overtimePay: 0,
      pieceWorkPay: 0,
      gratuities: 0,
      bonus: 0,
      otherCompensation: 'College credit',
      healthBenefits: false,
      dentalBenefits: false,
      retentionBonus: false,
      referralBonus: false,
      offer401k: false,
      paidTimeOff: false,
      otherBenefits: null,
      employerProvidesHousing: false,
      employerOwned: null,
      employerHousingAmount: null,
      workerHousingAmount: null,
      deposit: null,
      utilitiesIncluded: null,
      onProperty: null,
      offProperty: null,
      shared: null,
      individual: null,
      house: null,
      apartment: null,
      dormitory: null,
      otherHousing: null,
      employerProvidesTransportation: false,
      publicTransportation: true,
      shuttle: false,
      car: false,
      bicycle: true,
      carpool: false,
      otherTransport: null,
      transportCost: 0,
      applicationQuestions: ["First question","second question"],
    },
    // Add more mock jobs as needed
  ];

  constructor() {}

  fetchJobs(): Observable<JobPosition[]> {
    return of(this.jobs);
  }

  getJobById(id: number): Observable<JobPosition | undefined> {
    const job = this.jobs.find((job) => job.employerPositionId === id);
    return of(job);
  }
}
