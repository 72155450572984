import { HomeComponent } from './pages/home/home.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { ErrorComponent } from './pages/error/error.component';
import { AuthGuard } from '@auth0/auth0-angular';
import { SignupEmployerComponent } from './signup-employer/signup-employer.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SignupPaymentDetailsComponent } from './signup-payment-details/signup-payment-details.component';
import { SignupDoneComponent } from './signup-done/signup-done.component';
import { CancelSubscriptionComponent } from './pages/cancel-subscription/cancel-subscription.component';
import { ChangeSubscriptionComponent } from './pages/change-subscription/change-subscription.component';
import { BuySubscriptionComponent } from './pages/buy-subscription/buy-subscription.component';
import { SortContactsComponent } from './pages/sort-contacts/sort-contacts.component';
import { AcceptTermsComponent } from './pages/accept-terms/accept-terms.component';
import { SignupWorkerComponent } from './signup-worker/signup-worker.component';
import { SignupWorkerDoneComponent } from './signup-worker-done/signup-worker-done.component';
import { HousingContactFormComponent } from './pages/housing-contact-form/housing-contact-form.component';
import { ResourcesIndexComponent } from './pages/resources/index/index.component';
import { ResourceDetailComponent } from './pages/resources/resource-detail/resource-detail.component';
import { VideoPlayerComponent } from './components/video-player/video-player.component';
import { TransportationContactFormComponent } from './pages/transportation-contact-form/transportation-contact-form.component';
import { JobPostingsComponent } from './pages/jobs/job-postings/job-postings.component';

const routes: Routes = [
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'cancel-subscription',
    component: CancelSubscriptionComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'change-subscription',
    component: ChangeSubscriptionComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'buy-subscription',
    component: BuySubscriptionComponent,
    canActivate: [AuthGuard],
  },  
  {
    path: 'sort-contacts',
    component: SortContactsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'accept-terms',
    component: AcceptTermsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'resources',
    component: ResourcesIndexComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'resources/resource-detail',
    component: ResourceDetailComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'signup-employer',
    component: SignupEmployerComponent,
  },
  {
    path: 'signup-worker',
    component: SignupWorkerComponent,
  },
  {
    path: 'signup-done',
    component: SignupDoneComponent,
  },
  {
    path: 'signup-worker-done',
    component: SignupWorkerDoneComponent,
  },
  {
    path: 'signup-payment-details',
    component: SignupPaymentDetailsComponent,
  },

  {
    path: 'housing-contact-form',
    component: HousingContactFormComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'transportation-contact-form',
    component: TransportationContactFormComponent,
  },
  {
    path: 'error',
    component: ErrorComponent,
  },
  {
    path: '',
    component: HomeComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },

  {
    path: 'jobs/job-postings',
    component: JobPostingsComponent,
    canActivate: [AuthGuard],
  },

  { path: 'video', component: VideoPlayerComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
