// confirm-restore-dialog.component.ts
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-restore-dialog',
  templateUrl: './confirm-restore-dialog.component.html',
})
export class ConfirmRestoreDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmRestoreDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { differences: string[] }
  ) {}

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onYesClick(): void {
    this.dialogRef.close(true);
  }
}
