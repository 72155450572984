import { Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, filter, map, Observable, of, switchMap, take } from 'rxjs';
import { JobPosition } from '../models/JobModels';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root',
})
export class JobService {
  private jobsSubject = new BehaviorSubject<JobPosition[]>([]);
  private activeEmployerId: number | null = null;
  seasons: string[] = [];


  constructor(private apiService: ApiService) {
    this.activeEmployerId = Number.parseInt(sessionStorage.getItem('ActiveEmployerId') || '0', 10);
    this.loadJobs();
  }

  private loadJobs(): void {
    if (this.activeEmployerId) {
      this.apiService.getPositionsForEmployer(this.activeEmployerId).subscribe(
        (jobs) => {
          this.jobsSubject.next(jobs);
        }
      );
    }
  }

  refreshJobs(): any {
    this.loadJobs();
    return EMPTY;
  }

  fetchJobs(): Observable<JobPosition[]> {
    const sessionEmployerId = Number.parseInt(sessionStorage.getItem('ActiveEmployerId') || '0', 10);
    if (this.activeEmployerId !== sessionEmployerId) {
      this.activeEmployerId = sessionEmployerId;
      this.loadJobs();
    }
    return this.jobsSubject.asObservable();
  }

  getJobById(id: number): Observable<JobPosition | undefined> {
    return this.jobsSubject.asObservable().pipe(
      filter(jobs => jobs && jobs.length > 0), // Wait until jobs are loaded
      map(jobs => jobs.find(job => job.employerPositionId === id)),
      take(1), // Complete after the first emission
      switchMap(job => {
        if (!job) {
          // If job is not found, reload jobs and retry
          this.loadJobs();
          return this.jobsSubject.asObservable().pipe(
            filter(jobs => jobs && jobs.length > 0), // Wait until jobs are loaded
            map(jobs => jobs.find(job => job.employerPositionId === id)),
            take(1) // Complete after retry
          );
        }
        return of(job); // If job is found, return it
      })
    );
  }
  
}
