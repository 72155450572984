import { Component, OnInit, AfterViewInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors } from '@angular/forms';
import { ApiService } from '../../../api.service';
import { Applicant, JobPosition } from 'src/app/models/JobModels';
import { ApplicantFilterModalComponent } from 'src/app/components/applicant-filter-modal-component/applicant-filter-modal-component.component';
import { catchError, debounceTime, filter, switchMap, takeUntil, EMPTY, Subject } from 'rxjs';
import { AuthService, User } from '@auth0/auth0-angular';
import { SharedDataService } from 'src/app/shared-data-service.service';
import { Customer, Package } from 'src/app/models/ChargeOverModels';
import { EmployerContactSubscriptionDTO } from 'src/app/models/AccountManagementModels';
import { Router } from '@angular/router';
import { ErrorModalComponent } from 'src/app/error-modal/error-modal.component';
import { trigger, transition, useAnimation } from '@angular/animations';
import { bounceIn, fadeIn } from 'ng-animate';
import { PageEvent } from '@angular/material/paginator';
import { InfoModalComponent } from 'src/app/info-modal/info-modal.component';
import { ConfirmationCodeDialogComponent } from 'src/app/confirmation-code-dialog/confirmation-code-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSelectChange } from '@angular/material/select';
import { RejectReasonDialogComponent } from 'src/app/components/reject-reason-dialog/reject-reason-dialog.component';


@Component({
  selector: 'app-applicant-review',
  templateUrl: './applicant-review.component.html',
  styleUrls: ['./applicant-review.component.css'],
  animations: [
    trigger('bounceIn', [transition('* => *', useAnimation(bounceIn))]),
    trigger('fadeIn', [transition('* => *', useAnimation(fadeIn))])
  ]
})
export class ApplicantReviewComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = [
    'archive',
    'status',
    'resume',
    'firstName',
    'currentlyInCountry',
    'position',
    'citizenOfCountry',
    'requireSponsorship',
    'sponsorExpire',
    'h2bLimit'
  ];

  // We still use MatTableDataSource but no longer keep a full copy of allApplicants.
  dataSource = new MatTableDataSource<Applicant>([]);

  filterForm: FormGroup;
  positions: JobPosition[] = [];
  searchQuery: string = '';
  isGridLoading: boolean = false;

  isArchivedShown: boolean = false;

  selection = new SelectionModel<Applicant>(true, []);
  statuses: string[] = [
    '1st contact',
    '2nd contact',
    'Pending',
    'Interview scheduled',
    'Interview completed',
    'Offer extended',
    'Offer accepted',
    'Offer rejected',
    'Rejected',
    'Withdrawn'
  ];

  seasons: string[];

  selectedFilter: { position: string; startDate: string; onlyWithResume: boolean } = {
    position: '',
    startDate: '',
    onlyWithResume: false
  };

  isMobile: boolean = false;
  unsubscribe$ = new Subject<void>();
  customer: Customer;
  package: Package;
  employerContactSubscription: EmployerContactSubscriptionDTO;
  user: User;
  userType: string;
  portalURL: string;
  fadeIn: any;

  private _sort: MatSort;

  @ViewChild(MatSort)
  set sort(ms: MatSort) {
    if (ms) {
      this._sort = ms;  // Store the MatSort instance.
      this.dataSource.sort = ms;
      this.dataSource.sortingDataAccessor = (data: Applicant, sortHeaderId: string): any => {
        if (sortHeaderId === 'position') {
          const title = this.getPositionName(data.employerPositionId);
          return title || data.jobTitle;
        }
        return data[sortHeaderId];
      };
    }
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(public auth: AuthService,
              private apiService: ApiService, 
              private fb: FormBuilder, 
              public dialog: MatDialog,
              private snackBar: MatSnackBar,
              private sharedDataService: SharedDataService,
              private router: Router,
              private cd: ChangeDetectorRef) {
    // Note that in addition to your existing controls, you add worker type controls.
    this.filterForm = this.fb.group({
      season: [''],
      position: [''],
      status: [''],  
      availableStartDate: [''],
      availableEndDate: ['',this.endDateValidator.bind(this)],
      american: [false],
      inCountryH2B: [false],
      outOfCountryH2B: [false],
      j1: [false],
      onlyWithResume: [false],
      
    });
  }

  ngOnInit(): void {


    this.setupAuthSubscription();


    // Whenever the filter form changes, call the API (debounced).
    this.filterForm.valueChanges
      .pipe(debounceTime(300))
      .subscribe(() => {
        this.getApplicantsFromAPI();
      });
  }

  ngAfterViewInit(): void { 
  // Use the stored _sort instance.
  this.dataSource.sort = this._sort;
  this.dataSource.paginator = this.paginator;

  // Subscribe to sort changes using _sort.
  this._sort.sortChange.subscribe(() => {
    if (this.paginator) {
      this.paginator.pageIndex = 0;
    }
    this.getApplicantsFromAPI();
  });

  // Subscribe to paginator events.
  this.paginator.page.subscribe((event: PageEvent) => {
    this.getApplicantsFromAPI();
  });
}
  

  private setupAuthSubscription() {
    this.auth.user$
      .pipe(
        filter((user) => !!user && !!user.nickname),
        switchMap((user) => this.handleAuthUser(user)),
        catchError((e) => this.handleError(e)),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((employerContactSubscription) => {

        this.handleEmployerContactSubscription(employerContactSubscription);
      });
  }
  
  private handleAuthUser(user: User) {
    const arrMeta = user.nickname.split(':');
    const employerContactId = Number.parseInt(arrMeta[1], 10);
    this.userType = arrMeta[0];
    this.portalURL = this.sharedDataService.getData('portalURL');
    if (this.userType === 'E') {
      return this.apiService.getPrimaryEmployerForContact(employerContactId);
    } else if (this.userType === 'S') {
      this.redirectSeasoanalWorker();
      return EMPTY;
    } else if(this.userType === 'A') {
      return this.apiService.getEmployerForAdmin( Number.parseInt(sessionStorage.getItem('ActiveEmployerId') || '0',10));
    }
    return EMPTY;
  }

  private redirectSeasoanalWorker() {
    if (!this.portalURL) {
      this.apiService.GetConfigurationParam('PortalURL').subscribe((res) => {
        this.portalURL = res;
        this.sharedDataService.setData<string>('portalURL', res);
        window.location.href = this.portalURL;
      });
    } else {
      window.location.href = this.portalURL;
    }
  }

  private handleEmployerContactSubscription(
    employerContactSubscription: EmployerContactSubscriptionDTO
  ) {
    this.employerContactSubscription = employerContactSubscription; 
    if(employerContactSubscription.plan.subscriptionStatusId != 1)
    {
      this.router.navigate(['/'])
    }
    const employerId = employerContactSubscription.employer.employerId;
    const sessionEmployerId = Number.parseInt(sessionStorage.getItem('ActiveEmployerId') || '0', 10);

    if (!sessionStorage.getItem('ActiveEmployerId') || (Number.parseInt(sessionStorage.getItem('ActiveEmployerId') || '0', 10) != employerId)) {
      sessionStorage.setItem('ActiveEmployerId', employerId.toString());
    }
    this.loadPositions(employerId);
    this.getApplicantsFromAPI();
  }

  loadPositions(employerId:number): void {
    this.apiService.getPositionsForEmployer(employerId).subscribe((data: JobPosition[]) => {
      this.positions = data;
    });
    this.apiService.getSeasons().subscribe((data: string[]) => {
      this.seasons = data;
      this.retrieveSeason();
    });
  }

  retrieveSeason(): void{

     // Try to read the saved season from localStorage (if any)
     const savedSeason = localStorage.getItem('season')?.trim();

     // If a valid saved season exists, set it on the form control
     if (savedSeason && this.seasons.includes(savedSeason)) {
       this.filterForm.get('season').setValue(savedSeason);
     } else {
       // No valid season saved; determine one based on the current date
       let determinedSeason = this.determineSeason();
       // Fallback to the latest season if the heuristic doesn't match any
       if (!determinedSeason) {
         determinedSeason = this.seasons[this.seasons.length - 1];
       }
       // Set the form control and save to localStorage
       this.filterForm.get('season').setValue(determinedSeason);
       localStorage.setItem('season', determinedSeason);
     }
 
     // Subscribe to changes in the dropdown and update localStorage
     this.filterForm.get('season').valueChanges.subscribe(val => {
       localStorage.setItem('season', val);
     });
  }

  determineSeason(): string {
    const now = new Date();
    const month = now.getMonth() + 1; 

    let targetSeasonLabel: string;
    let targetYearStr: string;

    if (month >= 4 && month <= 9) {
      targetSeasonLabel = 'summer';
      targetYearStr = now.getFullYear().toString();
    } else {
      targetSeasonLabel = 'winter';
      if (month <= 3) {

        targetYearStr = now.getFullYear().toString();
      } else {
        targetYearStr = now.getFullYear().toString();
      }
    }

    let matchedSeason = this.seasons.find(season => {
      return season.toLowerCase().includes(targetSeasonLabel) &&
             season.includes(targetYearStr);
    });
    return matchedSeason;
  }

  getApplicantsFromAPI(): void {
    this.isGridLoading = true;
    const formValues = this.filterForm.value;
    const search = this.searchQuery ? this.searchQuery.trim() : '';
    const sortActive = this.dataSource.sort ? this.dataSource.sort.active : '';
    const sortDirection = this.dataSource.sort ? this.dataSource.sort.direction : '';
    const pageIndex = this.paginator ? this.paginator.pageIndex : 0;
    const pageSize = this.paginator ? this.paginator.pageSize : 10;

    const filteredFormValues = { ...formValues };

    // Remove boolean fields if they are false
    ['american', 'inCountryH2B', 'outOfCountryH2B', 'j1', 'onlyWithResume'].forEach(key => {
      if (!filteredFormValues[key]) {
        delete filteredFormValues[key];
      }
    });
    
    const formatDate = (date: Date): string => {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    };
    
    const params = {
      ...filteredFormValues,
      searchQuery: search,
      sortActive,
      sortDirection,
      pageIndex,
      pageSize,
      archived: this.isArchivedShown,
      availableStartDate: formValues.availableStartDate 
                            ? formatDate(new Date(formValues.availableStartDate))
                            : null,
      availableEndDate: formValues.availableEndDate 
                          ? formatDate(new Date(formValues.availableEndDate))
                          : null,
    };
    

    this.apiService.getApplicants(Number.parseInt(sessionStorage.getItem('ActiveEmployerId')),params)
      .pipe(
        catchError(error => {
          this.isGridLoading = false;

          this.openErrorModal(error);
          return EMPTY;
        })
      )
      .subscribe(response => {
        this.isGridLoading = false;

        this.dataSource.data = response.data.map(applicant => ({
          ...applicant,
          originalStatus: applicant.applicationStatus  // store the original status
        }));
        if (this.paginator && response.totalCount !== undefined) {
          this.paginator.length = response.totalCount;
        }
      });
  }

  clearFilter(): void {
    const savedSeason = localStorage.getItem('season')?.trim();

    this.filterForm.reset({
      position: '',
      status: '',
      availableStartDate: '',
      availableEndDate: '',
      american: false,
      inCountryH2B: false,
      outOfCountryH2B: false,
      j1: false,
      onlyWithResume: false,
      season: savedSeason
    });
    this.searchQuery = '';

    this.getApplicantsFromAPI();
    this.selection.clear();
  }

  swithcArchived(): void {
  this.isArchivedShown = !this.isArchivedShown;
  this.getApplicantsFromAPI();
  }

  archive(): void {
    const selectedCount = this.selection.selected.length;
    if (selectedCount === 0) {
      return;
    }
  
    const dialogRef = this.dialog.open(ConfirmationCodeDialogComponent, {
      data: {
        message: `Are you sure you want to archive ${selectedCount} job(s)?`
      }
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {  
        const applicationIds = this.selection.selected.map(applicant => applicant.applicationId);
        
        this.apiService.archiveApplicants(applicationIds, !this.isArchivedShown).subscribe(
          () => {
            this.selection.clear();
            this.getApplicantsFromAPI();
          },
          error => {
            this.handleError(error);
          }
        );
      }
    });
  }
  
  onStatusChange(newStatus: string, applicant: any): void {
    // Capture the previous status immediately.
    const currentStatus = applicant.applicationStatus;
  
    // For statuses that should not be updated via this dropdown, revert the change.
    if (['Offer accepted', 'Offer rejected', 'Withdrawn'].includes(newStatus)) {
      this.snackBar.open('This status can only be updated by the worker.', 'Close', { duration: 3000 });
      applicant.applicationStatus = applicant.originalStatus;
      this.cd.detectChanges();
      return;
    }
  
    // If the new status is "Rejected", open the modal for rejection reasons.
    if (newStatus === 'Rejected') {
      // Blur the active element to avoid ARIA warnings.
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
  
      const rejectionReasons = [
        'Dates of availability do not match',
        'Job filled',
        'Does not meet experience requirement',
        'Did not reply to employer',
        'Did not show for interview/no show',
        'Candidate accepted another offer',
        'Worker profile is incomplete, outdated, or contains inaccurate information'
      ];
  
      const dialogRef = this.dialog.open(RejectReasonDialogComponent, {
        data: { reasons: rejectionReasons },
        autoFocus: true,     
        restoreFocus: false 
      });
  
      dialogRef.afterClosed().subscribe(selectedReason => {
        if (selectedReason) {
          this.apiService.updateApplicantStatus(applicant.applicationId, newStatus, true, selectedReason)
            .subscribe(
              () => {
                applicant.originalStatus = newStatus;
                this.snackBar.open('Status updated successfully.', 'Close', { duration: 3000 });
              },
              error => {
                this.snackBar.open('Error updating status.', 'Close', { duration: 3000 });
                applicant.applicationStatus = applicant.originalStatus;
                this.cd.detectChanges();
              }
            );
        } else {
          // Revert to previous status if the dialog is canceled.
          applicant.applicationStatus = applicant.originalStatus;
          this.cd.detectChanges();
        }
      });
      return;
    }
  
    // For statuses that require confirmation (for example, "Offer extended")
    const statusesRequiringEmail = ['Offer extended'];
    const requiresEmail = statusesRequiringEmail.includes(newStatus);
  
    let message = `Your status will change to "${newStatus}".`;
    if (requiresEmail) {
      message += ' An email will be sent to the worker regarding this change.';
    }
    message += ' Do you want to proceed?';
  
    const dialogRef = this.dialog.open(ConfirmationCodeDialogComponent, {
      data: { message },
      autoFocus: true,     
      restoreFocus: false 
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.apiService.updateApplicantStatus(applicant.applicationId, newStatus, requiresEmail)
          .subscribe(
            () => {
              applicant.originalStatus = newStatus;
              this.snackBar.open('Status updated successfully.', 'Close', { duration: 3000 });
            },
            error => {
              this.snackBar.open('Error updating status.', 'Close', { duration: 3000 });
              applicant.applicationStatus = applicant.originalStatus;
              this.cd.detectChanges();
            }
          );
      } else {
        // If user cancels, revert the selection.
        applicant.applicationStatus = applicant.originalStatus;
        this.cd.detectChanges();
      }
    });
  }
  
  private endDateValidator(control: AbstractControl): ValidationErrors | null {
    const endDateValue = control.value;
    const parent = control.parent;
    if (!parent) {
      return null;
    }
    const startDateValue = parent.get('availableStartDate')?.value;
    if (!startDateValue || !endDateValue) {
      return null;
    }
    const startDate = new Date(startDateValue);
    const endDate = new Date(endDateValue);
    return endDate.getTime() > startDate.getTime()
      ? null
      : { dateRange: true };
  }

  performSearch(): void {
    this.getApplicantsFromAPI();
  }

  openFilterModal(): void {
    this.dialog.open(ApplicantFilterModalComponent, {
      width: '300px',
      data: { selectedFilter: this.selectedFilter }
    }).afterClosed().subscribe(result => {
      if (result) {
        this.selectedFilter = result;
        this.getApplicantsFromAPI();
      }
    });
  }

  applyMobileFilter(): void {
    this.getApplicantsFromAPI();
    this.dialog.closeAll();
  }
  
  getPositionName(employerPositionId: number): string {
    const pos = this.positions.find(p => p.employerPositionId === employerPositionId);
    return pos ? pos.jobTitle : '';
  }

  private handleError(error: any) {
    this.openErrorModal(error);
    return EMPTY;
  }

  openErrorModal(error: any): void {
    const dialogRef = this.dialog.open(ErrorModalComponent, {
      data: error,
      maxWidth: '70vw',
      maxHeight: '80vh'
    });
    dialogRef.afterClosed().subscribe(() => {});
  }


  // Helpers for Archive column selection.
  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle(): void {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }
}
